import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, deepPurple } from '@mui/material/colors';
import { green } from '@mui/material/colors';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Height } from '../util/ScreenHeight'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import { Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import ButtonGroup from '@mui/material/ButtonGroup';
import Pagination from '@mui/material/Pagination';
import TablePagination from '@mui/material/TablePagination';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        padding: '0px 0px',
        paddingRight: 20,
        paddingLeft: 5,
        // fontSize: 16,
    },
}));

const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        padding: '5px 0px',
        paddingRight: 5,
        paddingLeft: 5,
    },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function Smart(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [num, setNum] = React.useState([])
    const [result1, setResult1] = React.useState(null);
    const [page, setPage] = React.useState(1);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
        // setResult1(null)
    };

    const getMenu = (n) => {
        let param = {
            n: n,
            tp: props.tp
        }

        fetch("/lucky/v1/smart/menu", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setMenu(result)
                    }
                }
            );
    }

    const fetchData = (curPage) => {
        let param = {
            n: num,
            k: age,
            page: curPage
        }

        let lic = localStorage.getItem("lic")
        if (lic != null) param["lic"] = lic

        fetch("/lucky/v1/smart/data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        if (result.status === 0) {
                            setResult1(result)
                        } else if (result.status === -1 || result.status === -2) {
                            alert("無此類型包牌")
                            setResult1(null)
                        } else if (result.status === -3) {
                            alert("至少選擇" + result.minn + "-" + result.maxn + "個號碼")
                            setResult1(null)
                        } else if (result.status === -4) {
                            setResult1(null)
                        }

                    }
                }
            );
    }


    useEffect(() => {
        if (props.tp != null) {
            if (props.tp === 0) getMenu(6)
            else if (props.tp === 1) getMenu(5)
            else if (props.tp === 2) getMenu(6)
            else if (props.tp === 3) getMenu(12)
            else getMenu(6)
        } else getMenu(6)
    }, []);



    useEffect(() => {
        if (menu != null && menu.length > 0) {
            setAge(menu[0].n)
        }
    }, [menu]);

    const handleNumChange = (event, n1) => {
        // setResult1(null)

        if (event.target.checked) {
            if (!num.includes(n1)) {
                setNum([...num, n1]);
                // num.push(n1)
            }
        } else {
            const newArr = num.filter(e => e !== n1)
            setNum(newArr)
        }

    };

    const getColor = (top) => {
        // return "inherit"
        return "secondary"
        // if(top <= 10) {
        //     return "inherit"
        // } else if(top > 10 && top <= 20) {
        //     return "info"
        // } else if(top > 20 && top <= 30) {
        //     return "error"
        // }else if(top > 30 && top <= 40) {
        //     return "success"
        // } else return "warning"
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (age != "" && num != null && num.length >= fromNum()) {
            fetchData(page)
        } else {
            setResult1(null)
        }
    }, [num, page, age]);


    const search = () => {
        setPage(1)
        fetchData(1)
    }

    const fromNum = () => {
        if(props.tp != null) {
            if(props.tp === 0 || props.tp === 2) return 7
            else if(props.tp === 1) return 6
            else if(props.tp === 3) return 13
            else return 7
        } else return 7
    }

    const getMaxNum = () => {
        if(props.tp != null) {
            if(props.tp === 0) return 10
            else if(props.tp === 1) return 9
            else if(props.tp === 2) return 8

            else return 10
        } else return 10
    }    

    const getMoney = () => {
        if(props.tp != null) {
            if(props.tp === 0) return 50
            else if(props.tp === 1) return 50
            else if(props.tp === 2) return 100
            else if(props.tp === 3) return 50
            else return 50
        } else return 50
    }    

    return (
        <div style={{}}>
            <Box sx={{ m: 0.8 }}>
                <Stack direction={"row"} spacing={0.2}>
                    <FormControl size="small" fullWidth sx={{ flexGrow: 1 }} hiddenLabel>
                        {/* <InputLabel id="demo-simple-select-label">包牌</InputLabel> */}
                        <Select
                            // labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            // label="包牌"
                            onChange={handleChange}

                        >
                            {menu != null && menu.map(row => (
                                <MenuItem value={row.n} key={row.n}>中{row.w}保{row.k}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* <Button size="small" sx={{ whiteSpace: "nowrap", width: 120 }} color="secondary" disableElevation variant="contained" startIcon={<AutoFixNormalIcon />} onClick={e => setNum([])}>
                        清除
                    </Button> */}
                </Stack>
            </Box>

            <div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "scroll" }}>
                {<Typography variant="caption" sx={{ ml: 1 }}>
                    選擇{fromNum()}-20個號碼，非VIP最多回傳10注
                </Typography>}
                <Box sx={{ ml: 1, mb: 1, mr: 1 }}>
                    <TableContainer component={Paper} sx={{}}>
                        <Table sx={{}} aria-label="customized table" >
                            <TableBody>
                                <StyledTableRow key={"0"}>
                                    {[...Array(10).keys()].map(i =>
                                        <StyledTableCell align="center" key={i}>
                                            <FormControlLabel sx={{ mr: 0 }} control={<Checkbox sx={{ p: 0.5, '& .MuiSvgIcon-root': { fontSize: 28 } }} color="default" />} label={i + 1 < 10 ? "0" + (i + 1) : i + 1} onChange={e => handleNumChange(e, i + 1)} />
                                        </StyledTableCell>
                                    )}
                                </StyledTableRow>
                                <StyledTableRow key={"2"}>
                                    {[...Array(10).keys()].map(i =>
                                        <StyledTableCell align="center" key={i}>
                                            <FormControlLabel sx={{ mr: 0 }} control={<Checkbox sx={{ p: 0.5, '& .MuiSvgIcon-root': { fontSize: 28 } }} color="info" />} label={i + 11} onChange={e => handleNumChange(e, i + 11)} />
                                        </StyledTableCell>
                                    )}
                                </StyledTableRow>
                                <StyledTableRow key={"3"}>
                                    {[...Array(props.tp !== 3?10: 4).keys()].map(i =>
                                        <StyledTableCell align="center" key={i}>
                                            <FormControlLabel sx={{ mr: 0 }} control={<Checkbox sx={{ p: 0.5, '& .MuiSvgIcon-root': { fontSize: 28 } }} color="error" />} label={i + 21} onChange={e => handleNumChange(e, i + 21)} />
                                        </StyledTableCell>
                                    )}
                                </StyledTableRow>
                                {props.tp !== 3 && <StyledTableRow key={"4"}>
                                    {[...Array(getMaxNum()).keys()].map(i =>
                                        <StyledTableCell align="center" key={i}>
                                            <FormControlLabel sx={{ mr: 0 }} control={<Checkbox sx={{ p: 0.5, '& .MuiSvgIcon-root': { fontSize: 28 } }} color="success" />} label={i + 31} onChange={e => handleNumChange(e, i + 31)} />
                                        </StyledTableCell>
                                    )}
                                </StyledTableRow>}
                                {props.tp === 0 && <StyledTableRow key={"5"}>
                                    {[...Array(9).keys()].map(i =>
                                        <StyledTableCell align="center" key={i}>
                                            <FormControlLabel sx={{ mr: 0 }} control={<Checkbox sx={{ p: 0.5, '& .MuiSvgIcon-root': { fontSize: 28 } }} color="warning" />} label={i + 41} onChange={e => handleNumChange(e, i + 41)} />
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell align="center" key={'50'}>

                                    </StyledTableCell>
                                </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </Box>

                {/* result1 == null &&  */}


                {result1 != null && <Box sx={{ mt: 1.5, ml: 1, mb: 1, mr: 1 }}>
                    <Stack direction={"row"}>
                        <Pagination size="small"
                            siblingCount={0}
                            sx={{ flexGrow: 1 }}
                            count={result1.totalp}
                            variant="outlined" shape="rounded" onChange={handleChangePage}
                        />
                        <Typography variant='body2' sx={{ alignSelf: "end", fontWeight: "bold" }}>
                            {num.length}碼/{result1.total}注/{result1.total * getMoney()}元
                        </Typography>
                    </Stack>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={result1.data.length <= 25 ? 12 : 6}>
                            <TableContainer component={Paper} sx={{ mt: 1 }} >
                                <Table sx={{}} aria-label="customized table" size="small">
                                    <TableBody>
                                        {/* Math.ceil(result1.data.length / 2.0) */}
                                        {result1.data.slice(0, 25).map((row) => (
                                            <StyledTableRow key={Math.random().toString()}>
                                                <StyledTableCell1 align="center">
                                                    <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
                                                        {row.map((top) => (
                                                            <Button key={top} variant="contained" size="large" disableElevation color={getColor(top)}>{top}</Button>
                                                        ))}
                                                    </Stack>
                                                </StyledTableCell1>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TableContainer component={Paper} sx={{ mt: 1 }} >
                                <Table sx={{}} aria-label="customized table" size="small">
                                    <TableBody>
                                        {/* result1.data.slice(Math.ceil(result1.data.length / 2.0), result1.data.length) */}
                                        {result1.data.slice(25, result1.data.length).map((row) => (
                                            <StyledTableRow key={Math.random().toString()}>
                                                <StyledTableCell1 align="center">
                                                    <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
                                                        {row.map((top) => (
                                                            <Button key={top} variant="contained" size="large" disableElevation color={getColor(top)}>{top}</Button>
                                                        ))}
                                                    </Stack>
                                                </StyledTableCell1>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>



                </Box>}
            </div>
        </div >
    );
}
