import React, { useState, useEffect, useRef } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, ScatterChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { Height } from '../util/ScreenHeight'
import BigSetting1 from './BigSetting1';
import Divider from '@mui/material/Divider';
import BigTable71 from './BigTable71'
import Grid from '@mui/material/Grid';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


echarts.use(
    [ScatterChart, TitleComponent, TooltipComponent, GridComponent, LineChart, BarChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


export default function BigDetail7(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [result1, setResult1] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };


    function genoption1() {
        let k = {
            animation: false,
            grid: [{
                top: 30,
                left: 10,
                right: 20,
                height: 300,
                containLabel: true
            }],
            legend: {
                show: false
            },
            yAxis: [
                {
                    scale: false,
                    type: 'value',
                    // position: 'left',
                    show: true,
                    // splitLine: {
                    //     show: true,
                    //     lineStyle: {
                    //         opacity: 0.2
                    //     }
                    // },
                    // axisLabel: {
                    //     inside: true,
                    //     margin: 0
                    // },                    
                }],
            series: [
                {
                    name: '次數',
                    type: 'bar',
                    data: result.data.map(v => { return v.c }),
                    selectedMode: 'single',
                    markLine: {
                        symbol: "none",
                        label: {
                            show: true,
                            position: "insideStartTop",
                            formatter: '{b}',
                            fontWeight: "bold"
                        },
                        lineStyle: {
                            color: "red",
                            type: 'dashed'
                        },
                        data: [
                            {
                                name: '平均線',
                                yAxis: result.avg,
                                lineStyle: {
                                    width: 3,
                                }
                            }
                        ]
                    },
                }
            ]
        };

        let x1 = {
            type: 'category',
            boundaryGap: false,
            data: result.data.map(v => v.n)
        }

        if (fullScreen) {
            k['tooltip'] = getMobileTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            k['tooltip'] = getComputerTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getComputerDataZoom(0, 100, 470)
        }

        k['xAxis'] = [x1]
        return k;
    }

    const fetchData = () => {
        let period = localStorage.getItem('search_light') != null ? localStorage.getItem('search_light') : '0'
        let count = localStorage.getItem('search_count') != null ? localStorage.getItem('search_count') : '0'

        let param = {
            tp: props.tp
        }

        if (count != "0") param["count"] = count
        if (period != "0") param["period"] = period

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/lucky/v1/big/detail7", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }


    const fetchData1 = () => {
        let period = localStorage.getItem('search_light') != null ? localStorage.getItem('search_light') : '0'
        let count = localStorage.getItem('search_count') != null ? localStorage.getItem('search_count') : '0'

        let param = {
            tp: props.tp
        }

        if (count != "0") param["count"] = count
        if (period != "0") param["period"] = period
        if (age != "") param["sump"] = age

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/lucky/v1/big/detail71", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                kk => {
                    if (kk != null) {
                        setResult1(kk)
                    }
                }
            );
    }

    useEffect(() => {
        if (result != null) {
            setOption(genoption1)
            setAge(result.sump)
        }
    }, [result]);

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        if (age != "") fetchData1()
    }, [age]);

    return (
        <div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "scroll" }}>
            {openSetting ? <BigSetting1 refresh={fetchData} open={openSetting} close={setOpenSetting}></BigSetting1> : null}

            <Box sx={{ mt: 1, ml: 1, mb: 1 }}>
                <Typography variant='h6' sx={{ fontWeight: "bold" }}>
                    使用策略
                </Typography>
                {/* <Divider sx={{mt: 0.5, mb: 0.5}} /> */}
                <Typography variant='body1' sx={{ mr: 2 }}>
                    同期號碼加總起來稱作和值。統計一段時間的所有的和值，挑選常常出現的和值或某和值的下一期。
                </Typography>

                <Stack direction={"row"} spacing={0.5} sx={{ mt: 4, }}>
                    <Typography variant='h6' sx={{ alignSelf: "end", fontWeight: "bold" }}>
                        統計分析
                    </Typography>
                    <Button onClick={e => setOpenSetting(true)} color="secondary" variant="contained" size="small" disableElevation>設定期數</Button>
                </Stack>
                {/* <Divider sx={{mt: 0.5, mb: 0.5}} /> */}
                {/* <Typography variant='body1' sx={{ mr: 2 }}>
                出現次數統計從103年至最新一期
            </Typography> */}
                {option != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                    : null}

                <Typography variant='h6' sx={{ mt: 4, fontWeight: "bold" }}>
                    最新一期號碼
                </Typography>
                {result != null && <Typography sx={{ alignSelf: "left" }} variant='caption' >
                    {result.cur.num}
                </Typography>}
                {result != null &&
                    <Box sx={{width: "98%", overflowX: "auto" }}>
                        <Stack direction={"row"} spacing={0.8}>
                            <Button key={result.cur.n1} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n1}</Button>
                            <Button key={result.cur.n2} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n2}</Button>
                            <Button key={result.cur.n3} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n3}</Button>
                            <Button key={result.cur.n4} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n4}</Button>
                            <Button key={result.cur.n5} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n5}</Button>
                            {result.cur.n6 != null && result.cur.n6 != 0 && <Button key={result.cur.n6} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n6}</Button>}
                            {result.cur.n7 != null && result.cur.n7 != 0 && <Button key={result.cur.n7} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n7}</Button>}
                            {result.cur.n8 != null && result.cur.n8 != 0 && <Button key={result.cur.n8} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n8}</Button>}
                            {result.cur.n9 != null && result.cur.n9 != 0 && <Button key={result.cur.n9} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n9}</Button>}
                            {result.cur.n10 != null && result.cur.n10 != 0 && <Button key={result.cur.n10} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n10}</Button>}
                            {result.cur.n11 != null && result.cur.n11 != 0 && <Button key={result.cur.n11} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n11}</Button>}
                            {result.cur.n12 != null && result.cur.n12 != 0 && <Button key={result.cur.n12} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n12}</Button>}

                        </Stack>
                    </Box>
                }


                <Box sx={{ mt: 2, mr: 1.5 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">和值</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="和值"
                            onChange={handleChange}
                        >
                            {Array(264).fill().map((v, i) =>
                                <MenuItem key={i + 15} value={i + 15}>{i + 15}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Box>

                {result1 != null && <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <BigTable71 result={result1.a1} title={"相同和值"} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <BigTable71 result={result1.a2} title={"同和值下一期"} />
                    </Grid>
                </Grid>}


            </Box>
        </div>
    );
}

