import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import LSTMDetail from './LSTMDetail'
import RandomForest from './RandomForest';
import OLSDetail from './OLSDetail';
import RegreesionTree from './RegressionTree';
import HMM from './HMM';

export default function AIContainer(props) {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                // maxWidth: { xs: 320, sm: 480 },
                // bgcolor: 'background.paper',
                bgcolor: theme.palette.mode === "light"?"#f2f0f0":"#595959"
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                <Tab label="神經網路" />
                <Tab label="隱藏式馬可夫模型" />
                <Tab label="隨機森林" />
                <Tab label="線性回歸" />
                <Tab label="分類與迴歸樹" />
            </Tabs>

            {value === 0 && <LSTMDetail tp={props.tp}/>}
            {value === 1 && <HMM tp={props.tp}/>}
            {value === 2 && <RandomForest tp={props.tp}/>}
            {value === 3 && <OLSDetail tp={props.tp}/>}
            {value === 4 && <RegreesionTree tp={props.tp}/>}
        </Box>
    );
}
