import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green } from '@mui/material/colors';
import { Box } from '@mui/system';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { Divider, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function UserSign(props) {
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState(null);
    const [price, setPrice] = React.useState('p2')
    const [personName, setPersonName] = React.useState([]);
    const [openRec, setOpenRec] = React.useState(false);
    const [email, setEmail] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [checked, setChecked] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const handleOKClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) {
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);


    const inputReq = () => {
        let uid = localStorage.getItem("COL_UID")

        if (email == null || phone == null) {
            alert("請輸入電子信箱跟手機號碼")
        } else {
            let param = {
                email: email,
                phone: phone,
            }

            if (uid != null) param['iuid'] = uid


            fetch("/lucky/v1/account/sign1", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status === -1 || result.status === -2) {
                                alert("登入資訊有問題，請重新確認電子信箱與電話是否匹配。")
                                localStorage.removeItem("COL_UID")
                                localStorage.removeItem("COL_EMAIL")
                                localStorage.removeItem("COL_PHONE")
                            } else {
                                localStorage.setItem("COL_UID", result.user)
                                localStorage.setItem("COL_EMAIL", email)
                                localStorage.setItem("COL_PHONE", phone)
                                handleOKClose()
                            }
                        }
                    }
                );

        }
    }



    return (
        <div>
            <Dialog
                // fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"登入資訊"}
                </DialogTitle>
                <Divider />

                <DialogContent sx={{}}>
                    {localStorage.getItem("COL_EMAIL") == null || localStorage.getItem("COL_PHONE") == null ? <>
                        <Typography sx={{ mt: 0 }} variant='h5' fontWeight={"bold"}>登入資訊</Typography>
                        <Typography sx={{}} variant='body2'>登入資訊可同時用在手機/電腦/平板上。註冊後無法修改，請輸入正確並妥善保管。</Typography>

                        <TextField
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            autoFocus
                            focused
                            margin="dense"
                            id="name"
                            label="電子信箱"
                            type="email"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            autoFocus
                            focused
                            margin="dense"
                            id="name"
                            label="手機號碼"
                            fullWidth
                            variant="standard"
                        />
                    </> : null}
                </DialogContent>



                <DialogActions>
                    <Button fullWidth sx={{}} variant="contained" onClick={handleClose} autoFocus>
                        取消
                    </Button>
                    <Button fullWidth sx={{}} variant="contained" onClick={inputReq} autoFocus>
                        登入
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
