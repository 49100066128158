import React, { useState, useEffect, useRef } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, ScatterChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Height } from '../util/ScreenHeight'
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import AITable1 from './AITable1'

echarts.use(
    [ScatterChart, TitleComponent, TooltipComponent, GridComponent, LineChart, BarChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


export default function RandomForest(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



    const fetchData = () => {
        let tp = 1
        if(props.tp === 1) tp = 4
        else if(props.tp === 2) tp = 11
        else if(props.tp === 3) tp = 16

        let param = {
            tp: tp
        }
        
        let lic = localStorage.getItem("lic")
        if(lic != null) param["lic"] = lic

        fetch("/lucky/v1/deeplearning/ai", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }

    // useEffect(() => {
    //     if (result != null) setOption(genoption1)
    // }, [result]);

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "scroll" }}>

            <Box sx={{ mt: 1, ml: 1, mb: 1 }}>
                <Typography variant='h6' sx={{ fontWeight: "bold" }}>
                    使用策略
                </Typography>

                <Typography variant='body1' sx={{ mr: 2 }}>
                    擁有多棵決策數的隨機森林來預測出下期開出的數字。
                </Typography>

                <Stack direction={"row"} spacing={0.5} sx={{ mt: 4, mb: 0.5 }}>
                    <Typography variant='h6' sx={{ alignSelf: "end", fontWeight: "bold" }}>
                        預測結果
                    </Typography>
                </Stack>

                {/* {result != null && <BigTable2 result={result.data} title1="號碼" title2="拖牌TOP3" />} */}

                {/* {result != null && <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <AITable1 result={result.slice(0, 10)}  />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <AITable1 result={result.slice(10, 20)}  />
                    </Grid>
                </Grid>} */}
                <AITable1 result={result} tp={props.tp}/>

            </Box>
        </div>
    );
}

