import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { Height } from '../util/ScreenHeight'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function CheckContainer(props) {
    const theme = useTheme();
    const [light, setLight] = React.useState('2');
    const [count, setCount] = React.useState('10');

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openRec, setOpenRec] = React.useState(false);
    const [openUid, setOpenUid] = React.useState(null);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const fetchData = () => {

        let param = {
            tp: props.tp
        }

        if (count != "0") param["count"] = count
        // if (light != "0") param["period"] = light

        fetch("/lucky/v1/winner/data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result.data)
                    }
                }
            );
    }


    useEffect(() => {
        fetchData()
    }, [count, light]);


    const getColor = (row, win) => {
        if (row != null && row.win_list != null) {
            return row.win_list.includes(win)
        } else return false
    }

    const openRecClick = (uidd) => {
        setOpenUid(uidd)
        setOpenRec(true)
    }

    return (
        <div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "hidden" }}>
            <Box sx={{ m: 1 }}>
                <Stack spacing={0.5} direction="row" sx={{ p: 0.5 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">期別</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={count}
                            label="期別"
                            onChange={e => setCount(e.target.value)}
                        >
                            <MenuItem value={'1'}>{'最近1期'}</MenuItem>
                            <MenuItem value={'5'}>{'最近5期'}</MenuItem>
                            <MenuItem value={'10'}>{'最近10期'}</MenuItem>
                            <MenuItem value={'20'}>{'最近20期'}</MenuItem>
                            <MenuItem value={'30'}>{'最近30期'}</MenuItem>
                            <MenuItem value={'40'}>{'最近40期'}</MenuItem>
                        </Select>
                    </FormControl>
                    {/* <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">時間</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={light}
                            label="時間"
                            onChange={e => setLight(e.target.value)}
                        >

                            <MenuItem value={'1'}>{'最近1個月'}</MenuItem>
                            <MenuItem value={'2'}>{'最近2個月'}</MenuItem>
                            <MenuItem value={'3'}>{'最近3個月'}</MenuItem>
                            <MenuItem value={'4'}>{'最近4個月'}</MenuItem>
                            <MenuItem value={'5'}>{'最近5個月'}</MenuItem>
                            <MenuItem value={'6'}>{'最近6個月'}</MenuItem>
                        </Select>
                    </FormControl> */}
                </Stack>

            </Box>

            {result != null && <div style={{ width: "100%", position: 'absolute', top: Height.subheader + 25, bottom: 0, overflowY: "scroll" }}>
                <Box sx={{ ml: 1, mb: 1, mr: 1 }}>
                    <TableContainer component={Paper} sx={{}}  >
                        <Table sx={{}} aria-label="customized table" size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">期別 / 總獎金</StyledTableCell>
                                    {/* <StyledTableCell align="left">號碼</StyledTableCell> */}
                                    {props.tp != null && (props.tp === 0 || props.tp === 1 || props.tp === 3) && <StyledTableCell align="left">號碼</StyledTableCell>}
                                    {props.tp != null && props.tp === 2 && <StyledTableCell align="left">第一區</StyledTableCell>}

                                    {props.tp != null && props.tp === 0 && <StyledTableCell align="left">特別號</StyledTableCell>}
                                    {props.tp != null && props.tp === 2 && <StyledTableCell align="left">第二區</StyledTableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.map((row) => (
                                    <StyledTableRow key={row.num}>
                                        <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                                            <Stack>
                                            <Typography variant="caption">{row.ymd}</Typography>
                                                <Typography variant='subtitle1' fontWeight={"bold"}>{row.num}</Typography>
                                                <Typography variant="subtitle2">{(row.tm / 10000000).toFixed(1)}億</Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                                            <Stack direction={"column"} spacing={0.5} >
                                                <Stack direction={"row"} spacing={0.5}>
                                                    <Button variant="contained" size="large" disableElevation color="secondary" sx={{}}>{row.n1}</Button>
                                                    <Button variant="contained" size="large" disableElevation color="secondary">{row.n2}</Button>
                                                    <Button variant="contained" size="large" disableElevation color="secondary">{row.n3}</Button>
                                                </Stack>
                                                <Stack direction={"row"} spacing={0.5}>
                                                    <Button variant="contained" size="large" disableElevation color="secondary">{row.n4}</Button>
                                                    <Button variant="contained" size="large" disableElevation color="secondary">{row.n5}</Button>
                                                    {props.tp != null && (props.tp === 0 || props.tp === 2 || props.tp === 3) && <Button variant="contained" size="large" disableElevation color="secondary">{row.n6}</Button>}
                                                </Stack>
                                                {props.tp === 3 && <Stack direction={"row"} spacing={0.5}>
                                                    <Button variant="contained" size="large" disableElevation color="secondary">{row.n7}</Button>
                                                    <Button variant="contained" size="large" disableElevation color="secondary">{row.n8}</Button>
                                                    <Button variant="contained" size="large" disableElevation color="secondary">{row.n9}</Button>
                                                </Stack>}
                                                {props.tp === 3 && <Stack direction={"row"} spacing={0.5}>
                                                    <Button variant="contained" size="large" disableElevation color="secondary">{row.n10}</Button>
                                                    <Button variant="contained" size="large" disableElevation color="secondary">{row.n11}</Button>
                                                    <Button variant="contained" size="large" disableElevation color="secondary">{row.n12}</Button>
                                                </Stack>}
                                            </Stack>
                                        </StyledTableCell>
                                        {(props.tp === 0 || props.tp === 2) && <StyledTableCell align="left">
                                            <Button variant="contained" size="large" disableElevation color="error" sx={{height: "90px"}}>{row.sp}</Button>
                                        </StyledTableCell>}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>}

        </div>
    );
}
