import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green } from '@mui/material/colors';
import { Box } from '@mui/system';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { Divider, Stack } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function RuleDetail(props) {
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const handleOKClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) {
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);



    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"集資說明"}
                </DialogTitle>

                <DialogContent sx={{}}>
                    <Typography variant='body1' fontWeight={"bold"}>
                        集資類型有哪幾種？
                    </Typography>
                    <Typography variant='body1' >
                        第一種：預設都是集資的金額或注數達到當初設定的目標，才會去包牌購買。需等待較長時間，因沒有任何期限限制。<br />
                        第二種：限時。期限一到不論集資的金額是否有達到都會去包牌購買。
                    </Typography>

                    <Typography variant='body1' fontWeight={"bold"} sx={{ mt: 2 }}>
                        投注需要多少錢？
                    </Typography>
                    <Typography variant='body1' >
                        大樂透每次投注最少50元，最多500元。可重複投注。<br />
                        威力彩每次投注最少100元，最多500元。可重複投注。<br />
                    </Typography>

                    <Typography variant='body1' fontWeight={"bold"} sx={{ mt: 2 }}>
                        包牌的號碼如何產生？
                    </Typography>
                    <Typography variant='body1' >
                        參與集資的前百分之20%的人投注時需選擇數個號碼，號碼依照選擇人數的多寡排序，在從前面挑出所需要的號碼數量。
                    </Typography>

                    <Typography variant='body1' fontWeight={"bold"} sx={{ mt: 2 }}>
                        我建議的號碼都會被選中嗎？
                    </Typography>
                    <Typography variant='body1' >
                        不一定。號碼依照選擇人數的多寡排序，在從前面挑出所需要的號碼數量。
                    </Typography>

                    <Typography variant='body1' fontWeight={"bold"} sx={{ mt: 2 }}>
                        參與集資需要輸入什麼個人資料？
                    </Typography>
                    <Typography variant='body1' >
                        提供一組Email跟手機號碼作為登入的依據，無法修改。
                    </Typography>

                    <Typography variant='body1' fontWeight={"bold"} sx={{ mt: 2 }}>
                        中獎獎金如何分配？
                    </Typography>
                    <Typography variant='body1' >
                        總獎金：集資所購買的彩券，中獎金額的總和<br />
                        所得稅：中獎金額5000元(不含)以上獎金＊20%<br />
                        印花稅：中獎金額5000元(不含)以上獎金＊0.4%<br />
                        服務費：(總獎金 - 所得稅 - 印花稅)＊1.5%<br />
                        實際獎金：總獎金 - 所得稅 - 印花稅 - 服務費<br />
                        單注獎金 = 實際獎金 / 總注數<br />
                        個人獎金 = 單注獎金 * 個人投資總注數<br />
                    </Typography>

                    <Typography variant='body1' fontWeight={"bold"} sx={{ mt: 2 }}>
                        為何要收取服務費？
                    </Typography>
                    <Typography variant='body1' >
                        我們只有中獎時才須收取服務費，服務費會用於平台維護，購買彩券與兌獎等人工花費。
                    </Typography>
                    <Typography variant='body1' fontWeight={"bold"} sx={{ mt: 2 }}>
                        中獎獎金如何領出？還需要額外的費用嗎？
                    </Typography>
                    <Typography variant='body1' >
                        提出轉出申請需填寫個人姓名/轉入的銀行帳戶，並扣除20元轉帳手續費。轉帳會在2-3天完成。
                    </Typography>

                    <Typography variant='body1' fontWeight={"bold"} sx={{ mt: 2 }}>
                        集資中途想要退出，要怎麼辦理？
                    </Typography>
                    <Typography variant='body1' >
                        集資尚未完成時，都可以提出取消申請，轉出同樣需扣除20元轉帳手續費。轉帳會在2-3天完成。
                    </Typography>

                    <Typography variant='body1' fontWeight={"bold"} sx={{ mt: 2 }}>
                        中7保6是指什麼?
                    </Typography>
                    <Typography variant='body1' >
                        挑選的數字中，有包含開獎的7個數字，保證至少會中一組6個數字(大樂透的頭獎或者貳獎)。
                    </Typography>

                    <Typography variant='body1' fontWeight={"bold"} sx={{ mt: 2 }}>
                        選擇了中7保6，若最後只包含開獎的6個或5個數字，這樣會中3獎或4獎嗎？
                    </Typography>
                    <Typography variant='body1' >
                        沒有保證。相同的道理，選擇中6保5的策略，若只有中5個數字，也不保證會中任何獎。
                    </Typography>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
