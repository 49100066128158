import React, { useState, useEffect, useRef } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, ScatterChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import BigTable2 from './BigTable2'
import { Height } from '../util/ScreenHeight'
import BigSetting1 from './BigSetting1';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

echarts.use(
    [ScatterChart, TitleComponent, TooltipComponent, GridComponent, LineChart, BarChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


export default function BigDetail6(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const fetchData = () => {
        let period = localStorage.getItem('search_light') != null ? localStorage.getItem('search_light') : '0'
        let count = localStorage.getItem('search_count') != null ? localStorage.getItem('search_count') : '0'

        let param = {
            tp: props.tp
        }

        if (count != "0") param["count"] = count
        if (period != "0") param["period"] = period

        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }

        fetch("/lucky/v1/big/detail6", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }

    // useEffect(() => {
    //     if (result != null) setOption(genoption1)
    // }, [result]);

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "scroll" }}>
            {openSetting ? <BigSetting1 refresh={fetchData} open={openSetting} close={setOpenSetting}></BigSetting1> : null}
            <Box sx={{ mt: 1, ml: 1, mb: 1 }}>
                <Typography variant='h6' sx={{ fontWeight: "bold" }}>
                    使用策略
                </Typography>

                <Typography variant='body1' sx={{ mr: 2 }}>
                    統計尾數與其他尾數一同出現的機率，選出前三名。
                </Typography>

                <Stack direction={"row"} spacing={0.5} sx={{ mt: 4, mb: 0.5 }}>
                    <Typography variant='h6' sx={{ alignSelf: "end", fontWeight: "bold" }}>
                        統計分析
                    </Typography>
                    <Button onClick={e => setOpenSetting(true)} color="secondary" variant="contained" size="small" disableElevation>設定期數</Button>
                </Stack>
                {/* <Typography variant='body1' sx={{ mr: 2 }}>
            一同出現的統計次數從103年至最新一期
            </Typography> */}

                {/* {result != null && <BigTable2 result={result.data} title1="號碼" title2="相鄰TOP3" />} */}
                {result != null && <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <BigTable2 result={result.data.slice(0, 5)} title1="尾數" title2="尾數相鄰TOP3" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <BigTable2 result={result.data.slice(5, 10)} title1="尾數" title2="尾數相鄰TOP3" />
                    </Grid>
                </Grid>}

                <Typography variant='h6' sx={{ mt: 4, fontWeight: "bold" }}>
                    最新一期號碼
                </Typography>
                {result != null && <Typography sx={{ alignSelf: "left" }} variant='caption' >
                    {result.cur.num}
                </Typography>}

                {result != null &&
                    <Box sx={{ width: "98%", overflowX: "auto" }}>
                        <Stack direction={"row"} spacing={0.8}>
                            <Button key={result.cur.n1} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n1}</Button>
                            <Button key={result.cur.n2} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n2}</Button>
                            <Button key={result.cur.n3} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n3}</Button>
                            <Button key={result.cur.n4} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n4}</Button>
                            <Button key={result.cur.n5} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n5}</Button>
                            {result.cur.n6 != null && result.cur.n6 != 0 && <Button key={result.cur.n6} color="secondary" disableElevation size="large" sx={{}} variant="contained">{result.cur.n6}</Button>}
                        </Stack>
                    </Box>
                }

                {result != null && <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <BigTable2 result={result.rec.slice(0, 3)} title1="尾數" title2="尾數相鄰TOP3" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <BigTable2 result={result.rec.slice(3, 6)} title1="尾數" title2="尾數相鄰TOP3" />
                    </Grid>
                </Grid>}




            </Box>
        </div>
    );
}

