export const Height = {
    header:60,
    subheader: 50,
    footer: 58
}


// export default Height;



