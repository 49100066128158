import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import BaseItem from './BaseItem'

export default function CollectItem(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const [openHighLow, setOpenHighLow] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);

    const getStatus = () => {
        if (props.row != null && props.row.data != null) {
            if (props.row.data.status === 0) return "未開始"
            else if (props.row.data.status === 1) return "集資中"
            else if (props.row.data.status === 2) return "已開獎"
            else return ""
        } else return ""
    }

    const getColor = (num) => {
        if (props.row != null) {
            if (props.row.num.includes(num))
                return "error"
            else return "secondary"
        } else return "secondary"
    }


    const clickInput = () => {
        if (props.openIn != null) {
            props.openIn(props.row.data.id, props.row)
        }
    }

    return (
        <Stack spacing={0} sx={{ m: 1, mb: 0.5, p: 0, border: 2, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0.5, borderRadius: '0px' }}>
            <BaseItem row={props.row} />


            <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                <Button sx={{ width: '33%' }} color="info">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>規則</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold' }} onClick={e => props.setOpenRec(true)} >說明</Typography>
                    </Stack>

                </Button>
                <Button sx={{ width: '67%' }} color="warning">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>狀態</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold' }} >{getStatus()}</Typography>
                        
                    </Stack>
                </Button>
                {/* <Button sx={{ width: '33%' }} color="success">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>開獎期別</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold', }}  >{props.row.data.periodi == null ? "無" : props.row.data.periodi}</Typography>
                    </Stack>
                </Button> */}
            </ButtonGroup>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} variant="middle" />

            <Stack direction={"row"} sx={{ mt: 1, alignSelf: "center" }} spacing={0.5}>
                <Typography fontWeight={"bold"} variant='subtitle1' >目前號碼</Typography>
                {/* <Typography variant='caption' sx={{ alignSelf: "center" }}>查看選號數量</Typography> */}
            </Stack>
            <Stack sx={{ m: 1 }} direction={"column"} spacing={0.5}>
                <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                    <Button key="01" variant="contained" size="small" disableElevation color={getColor(1)}>01</Button>
                    <Button key="02" variant="contained" size="small" disableElevation color={getColor(2)}>02</Button>
                    <Button key="03" variant="contained" size="small" disableElevation color={getColor(3)}>03</Button>
                    <Button key="04" variant="contained" size="small" disableElevation color={getColor(4)}>04</Button>
                    <Button key="05" variant="contained" size="small" disableElevation color={getColor(5)}>05</Button>
                    <Button key="06" variant="contained" size="small" disableElevation color={getColor(6)}>06</Button>
                    <Button key="07" variant="contained" size="small" disableElevation color={getColor(7)}>07</Button>
                </ButtonGroup>
                <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                    <Button key="08" variant="contained" size="small" disableElevation color={getColor(8)}>08</Button>
                    <Button key="09" variant="contained" size="small" disableElevation color={getColor(9)}>09</Button>
                    <Button key="10" variant="contained" size="small" disableElevation color={getColor(10)}>10</Button>
                    <Button key="11" variant="contained" size="small" disableElevation color={getColor(11)}>11</Button>
                    <Button key="12" variant="contained" size="small" disableElevation color={getColor(12)}>12</Button>
                    <Button key="13" variant="contained" size="small" disableElevation color={getColor(13)}>13</Button>
                    <Button key="14" variant="contained" size="small" disableElevation color={getColor(14)}>14</Button>
                </ButtonGroup>
                <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                    <Button key="15" variant="contained" size="small" disableElevation color={getColor(15)}>15</Button>
                    <Button key="16" variant="contained" size="small" disableElevation color={getColor(16)}>16</Button>
                    <Button key="17" variant="contained" size="small" disableElevation color={getColor(17)}>17</Button>
                    <Button key="18" variant="contained" size="small" disableElevation color={getColor(18)}>18</Button>
                    <Button key="19" variant="contained" size="small" disableElevation color={getColor(19)}>19</Button>
                    <Button key="20" variant="contained" size="small" disableElevation color={getColor(20)}>20</Button>
                    <Button key="21" variant="contained" size="small" disableElevation color={getColor(21)}>21</Button>
                </ButtonGroup>
                <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                    <Button key="22" variant="contained" size="small" disableElevation color={getColor(22)}>22</Button>
                    <Button key="23" variant="contained" size="small" disableElevation color={getColor(23)}>23</Button>
                    <Button key="24" variant="contained" size="small" disableElevation color={getColor(24)}>24</Button>
                    <Button key="25" variant="contained" size="small" disableElevation color={getColor(25)}>25</Button>
                    <Button key="26" variant="contained" size="small" disableElevation color={getColor(26)}>26</Button>
                    <Button key="27" variant="contained" size="small" disableElevation color={getColor(27)}>27</Button>
                    <Button key="28" variant="contained" size="small" disableElevation color={getColor(28)}>28</Button>
                </ButtonGroup>
                <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                    <Button key="29" variant="contained" size="small" disableElevation color={getColor(29)}>29</Button>
                    <Button key="30" variant="contained" size="small" disableElevation color={getColor(30)}>30</Button>
                    <Button key="31" variant="contained" size="small" disableElevation color={getColor(31)}>31</Button>
                    <Button key="32" variant="contained" size="small" disableElevation color={getColor(32)}>32</Button>
                    <Button key="33" variant="contained" size="small" disableElevation color={getColor(33)}>33</Button>
                    <Button key="34" variant="contained" size="small" disableElevation color={getColor(34)}>34</Button>
                    <Button key="35" variant="contained" size="small" disableElevation color={getColor(35)}>35</Button>
                </ButtonGroup>
                <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                    <Button key="36" variant="contained" size="small" disableElevation color={getColor(36)}>36</Button>
                    <Button key="37" variant="contained" size="small" disableElevation color={getColor(37)}>37</Button>
                    <Button key="38" variant="contained" size="small" disableElevation color={getColor(38)}>38</Button>
                    <Button key="39" variant="contained" size="small" disableElevation color={getColor(39)}>39</Button>
                    <Button key="40" variant="contained" size="small" disableElevation color={getColor(40)}>40</Button>
                    <Button key="41" variant="contained" size="small" disableElevation color={getColor(41)}>41</Button>
                    <Button key="42" variant="contained" size="small" disableElevation color={getColor(42)}>42</Button>
                </ButtonGroup>
                <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                    <Button key="43" variant="contained" size="small" disableElevation color={getColor(43)}>43</Button>
                    <Button key="44" variant="contained" size="small" disableElevation color={getColor(44)}>44</Button>
                    <Button key="45" variant="contained" size="small" disableElevation color={getColor(45)}>45</Button>
                    <Button key="46" variant="contained" size="small" disableElevation color={getColor(46)}>46</Button>
                    <Button key="47" variant="contained" size="small" disableElevation color={getColor(47)}>47</Button>
                    <Button key="48" variant="contained" size="small" disableElevation color={getColor(48)}>48</Button>
                    <Button key="49" variant="contained" size="small" disableElevation color={getColor(49)}>49</Button>
                </ButtonGroup>
            </Stack>

            {/* <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} variant="middle" /> */}

            {props.row.data.status === 1 && <Button onClick={e => clickInput()} startIcon={<HailOutlinedIcon />} size="large" sx={{ mt: 2, borderRadius: 0 }} disableElevation fullWidth variant='contained' color="inherit">我要加入</Button>}

            {/* <div style={{display:'flex'}}>
                <Stack sx={{ m: 1, flex: 1 }} direction={'row'} spacing={0.5}>
                    <Button startIcon={<Lightbulb />} color={props.row.inl ? "error" : "inherit"} disableElevation size="small" variant="contained" disabled={!props.row.inl} >進場燈</Button>
                    <Button startIcon={<Lightbulb />} color={props.row.outl ? "success" : "inherit"} disableElevation size="small" variant="contained" disabled={!props.row.outl} >出場燈</Button>
                    {props.row.status != null && props.row.status > 0 && <Avatar sx={{ height: '30px', width: '30px', bgcolor: deepOrange[500] }} variant="rounded">
                        持
                    </Avatar>}
                </Stack>
                {props.islove && <IconButton sx={{}}  onClick={e => handleMenuClick(e)}>
                    <MoreVertIcon />
                </IconButton>}
                <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={e => handleMenuChange(0)}>修改</MenuItem>
                    </Menu>
            </div> */}
        </Stack>

    );
}
