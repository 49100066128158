import React from 'react'
import { BrowserRouter as Router, Route, HashRouter, Routes } from 'react-router-dom'
import CHome from './main/CHome'
import CGHome from './gmain/CHome'
import IOSHome from './iosmain/CHome'

export default props => (
  <HashRouter>
    <Routes>
      <Route exact path='/' element={<CHome />} />
      <Route exact path='/gool' element={<CGHome />} />
      <Route exact path='/ios' element={<IOSHome />} />
      <Route exact path='/open' element={ <CHome/> } />  
    </Routes>
  </HashRouter>
)