import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

export default function BigSetting1(props) {
    const [open, setOpen] = React.useState(false);
    const [sort, setSort] = React.useState(localStorage.getItem('search_sort') != null ? localStorage.getItem('search_sort') : '0');
    const [light, setLight] = React.useState(localStorage.getItem('search_light') != null ? localStorage.getItem('search_light') : '0');
    const [count, setCount] = React.useState(localStorage.getItem('search_count') != null ? localStorage.getItem('search_count') : '0');


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        // localStorage.setItem("search_sort", sort)
        localStorage.setItem("search_light", light)
        localStorage.setItem("search_count", count)

        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"統計分析設定"}
                    <Typography color="red" variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', ml: 1 }}>VIP專屬</Typography>
                </DialogTitle>
                <DialogContent>

                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">計算周期</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={count}
                                label="計算周期"
                                onChange={e => setCount(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'全部期數'}</MenuItem>
                                <MenuItem value={'1'}>{'近1期'}</MenuItem>
                                <MenuItem value={'5'}>{'近5期'}</MenuItem>
                                <MenuItem value={'10'}>{'近10期'}</MenuItem>
                                <MenuItem value={'20'}>{'近20期'}</MenuItem>
                                <MenuItem value={'30'}>{'近30期'}</MenuItem>
                                <MenuItem value={'40'}>{'近40期'}</MenuItem>
                                <MenuItem value={'50'}>{'近50期'}</MenuItem>
                                <MenuItem value={'100'}>{'近100期'}</MenuItem>
                                <MenuItem value={'200'}>{'近200期'}</MenuItem>
                                <MenuItem value={'300'}>{'近300期'}</MenuItem>
                                <MenuItem value={'400'}>{'近400期'}</MenuItem>
                                <MenuItem value={'500'}>{'近500期'}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">計算時間</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={light}
                                label="計算時間"
                                onChange={e => setLight(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'全部時間'}</MenuItem>
                                <MenuItem value={'1'}>{'近1個月'}</MenuItem>
                                <MenuItem value={'2'}>{'近2個月'}</MenuItem>
                                <MenuItem value={'3'}>{'近3個月'}</MenuItem>
                                <MenuItem value={'4'}>{'近4個月'}</MenuItem>
                                <MenuItem value={'5'}>{'近5個月'}</MenuItem>
                                <MenuItem value={'6'}>{'近6個月'}</MenuItem>
                                <MenuItem value={'7'}>{'近7個月'}</MenuItem>
                                <MenuItem value={'8'}>{'近8個月'}</MenuItem>
                                <MenuItem value={'9'}>{'近9個月'}</MenuItem>
                                <MenuItem value={'10'}>{'近10個月'}</MenuItem>
                                <MenuItem value={'12'}>{'近1年'}</MenuItem>
                                <MenuItem value={'24'}>{'近2年'}</MenuItem>
                                <MenuItem value={'36'}>{'近3年'}</MenuItem>
                                <MenuItem value={'48'}>{'近4年'}</MenuItem>
                                <MenuItem value={'60'}>{'近5年'}</MenuItem>
                                <MenuItem value={'72'}>{'近6年'}</MenuItem>
                                <MenuItem value={'84'}>{'近7年'}</MenuItem>                                                                
                            </Select>
                        </FormControl>
                    </Stack>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button disabled={localStorage.getItem("noad") != "1"} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
