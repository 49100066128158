import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green } from '@mui/material/colors';
import { Box } from '@mui/system';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { Divider, Stack } from '@mui/material';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MyMoneyList(props) {
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const handleOKClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) {
            setOpen(true)
            inputReq()
        } else setOpen(false)
    }, [props.open]);

    const inputReq = () => {
        let uid = localStorage.getItem("COL_UID")
        let email = localStorage.getItem("COL_EMAIL")
        let phone = localStorage.getItem("COL_PHONE")

        if (email == null || phone == null || uid == null) {
            alert("請重新登入")

            localStorage.removeItem("COL_UID")
            localStorage.removeItem("COL_EMAIL")
            localStorage.removeItem("COL_PHONE")
        } else {
            let param = {
                email: email,
                phone: phone,
                uid: uid
            }

            fetch("/lucky/v1/money/list", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status === -2) {
                                alert("登入資訊有問題，請重新確認電子信箱與電話是否匹配。")
                                localStorage.removeItem("COL_UID")
                                localStorage.removeItem("COL_EMAIL")
                                localStorage.removeItem("COL_PHONE")
                            } else if (result.status === 0) {
                                setResult(result.my)
                            }
                        }
                    }
                );

        }
    }

    return (
        <div>
            <Dialog
                // fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="responsive-dialog-title">
                    {"帳戶進出紀錄"}
                </DialogTitle>
                <Divider />

                <DialogContent sx={{}}>
                    <Typography variant='body1' fontWeight={"bold"} >帳戶餘額：{props.money}元</Typography>
                    {result != null && result.length === 0 && <Typography variant='body1' >目前沒有任何進出紀錄</Typography>}
                    {result != null && result.length > 0 && <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 300 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>日期</TableCell>
                                    <TableCell>名稱</TableCell>
                                    <TableCell align="right">金額</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result != null && result.length > 0 && result.map(row => (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        key={row.ts}
                                    >


                                        <TableCell component="th" scope="row">
                                            {new Date(row.ts).toLocaleString()}
                                        </TableCell>
                                        <TableCell align="right">{row.desc}</TableCell>
                                        <TableCell align="right">{row.money}</TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}

                </DialogContent>
                <DialogActions>
                    <Button fullWidth sx={{}} variant="contained" onClick={handleClose} autoFocus>
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
