import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green } from '@mui/material/colors';
import { Box } from '@mui/system';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { Divider, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import BaseItem from './BaseItem';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RuleDetail from './RuleDetail'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function InputDetail(props) {
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState(null);
    const [price, setPrice] = React.useState('p2')
    const [personName, setPersonName] = React.useState([]);
    const [openRec, setOpenRec] = React.useState(false);
    const [email, setEmail] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [checked, setChecked] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const handleOKClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) {
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);

    const openPayConsole = (uid, email, phone) => {
        let url = "/lucky/v1/collect/input1?iuid=" + uid + "&email=" + email 
        + "&phone=" + phone + "&pid=" + price + "&num=" + personName.join(",") 
        +  "&gid=" + props.gid + "#open"
        window.open(url, "_blank", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1024,height=800");
    
        handleOKClose()
    }

    const inputReq = () => {
        let e = email
        let p = phone
        let uid = localStorage.getItem("COL_UID")

        if (localStorage.getItem("COL_EMAIL") != null && localStorage.getItem("COL_PHONE") != null) {
            e = localStorage.getItem("COL_EMAIL")
            p = localStorage.getItem("COL_PHONE")
        }

        if(personName != null && personName.length > 6) {
            alert("最多建議6個號碼")
        } else if(!checked) {
            alert("請同意集資說明內容")
        } else if (e == null || e == "" || p == null || p == "") {
            alert("請填入登入資訊")
        } else {

            let param = {
                email: e,
                phone: p,
                pid: price,
                num: personName,
                gid: props.gid
            }
            if (uid != null) param['iuid'] = uid


            fetch("/lucky/v1/collect/input", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status === -1 || result.status === -2) {
                                alert("登入資訊有問題，請重新確認電子信箱與電話是否匹配。")
                                localStorage.removeItem("COL_UID")
                                localStorage.removeItem("COL_EMAIL")
                                localStorage.removeItem("COL_PHONE")                            
                            } else if (result.status === -3)
                                alert("無此集資活動")
                            else if (result.status === -4)
                                alert("此集資活動已經結束")
                            else if (result.status === -5)
                                alert("輸入金額有問題，請重新選擇。")
                            else {
                                localStorage.setItem("COL_UID", result.uid)
                                localStorage.setItem("COL_EMAIL", e)
                                localStorage.setItem("COL_PHONE", p)
                                openPayConsole(result.uid, e, p)
                            }
                        }
                    }
                );
        }

    }



    return (
        <div>
            {openRec ? <RuleDetail open={openRec} close={setOpenRec}></RuleDetail> : null}

            <Dialog
                // fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"參加集資"}
                </DialogTitle>
                <Divider />

                <DialogContent sx={{}}>
                    {localStorage.getItem("COL_EMAIL") == null || localStorage.getItem("COL_PHONE") == null ? <>
                        <Typography sx={{ mt: 0 }} variant='h5' fontWeight={"bold"}>登入資訊</Typography>
                        <Typography sx={{}} variant='body2'>登入資訊可同時用在手機/電腦/平板上。註冊後無法修改，請輸入正確並妥善保管。</Typography>

                        <TextField
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            autoFocus
                            focused
                            margin="dense"
                            id="name"
                            label="電子信箱"
                            type="email"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            autoFocus
                            focused
                            margin="dense"
                            id="name"
                            label="手機號碼"
                            fullWidth
                            variant="standard"
                        />
                    </> : null}

                    <Typography sx={{ mt: localStorage.getItem("COL_EMAIL") == null || localStorage.getItem("COL_PHONE") == null ? 4 : 0 }} variant='h5' fontWeight={"bold"}>集資資訊</Typography>
                    <TextField
                        select
                        autoFocus
                        focused
                        margin="dense"
                        id="name"
                        label="付款金額"
                        fullWidth
                        variant="standard"
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                    >

                        <MenuItem value={'p1'}>{'50元'}</MenuItem>
                        <MenuItem value={'p2'}>{'100元'}</MenuItem>
                        <MenuItem value={'p3'}>{'200元'}</MenuItem>
                        <MenuItem value={'p4'}>{'300元'}</MenuItem>
                        <MenuItem value={'p5'}>{'400元'}</MenuItem>
                        <MenuItem value={'p6'}>{'500元'}</MenuItem>
                    </TextField>

                    <FormControl variant="standard" sx={{ mt: 2, mb: 2 }} fullWidth focused>
                        <InputLabel id="demo-multiple-chip-label">建議號碼(至多6個，非必要)</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip color="error" key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {[...Array(props.row.total).keys()].map(index => {
                                return <MenuItem  key={index + 1} value={index + 1}>{index + 1}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControlLabel onClick={e => setOpenRec(true)} control={<Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}/>} label="了解並同意集資的說明內容" />
                    {/* <FormControlLabel sx={{ mt: 1.5, flexGrow: 1 }} control={<Checkbox color="error" checked={checked} onChange={handleAgreeChange} />} label={<Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} onClick={e => setOpenAsk(true)}>同意使用條款和使用者政策</Typography>} /> */}
                </DialogContent>



                <DialogActions>
                    <Button disableElevation color="inherit" fullWidth sx={{}} variant="contained" onClick={handleClose} autoFocus>
                        取消
                    </Button>
                    <Button disableElevation color="inherit" fullWidth sx={{}} variant="contained" onClick={inputReq} autoFocus>
                        付款
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
