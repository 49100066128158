import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green } from '@mui/material/colors';
import { Box } from '@mui/system';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { Divider, Stack } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function RecDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const handleOKClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) {
            fetchData()
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);


    const fetchData = () => {
        let param = {
            uid: props.uid,
            tp : props.tp
        }

        fetch("/lucky/v1/big/userreclist", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }

    const getColor = (row, win) => {
        if (row != null && row.win_list != null) {
            return row.win_list.includes(win)
        } else return false
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"歷史推薦結果"}
                </DialogTitle>
                
                <DialogContent sx={{p: 1}}>
                    {/* <Box sx={{m: 1}}> */}
                    <Divider variant="fullWidth" sx={{}}></Divider>

                    {result != null && <Box sx={{  }} >
                        <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                            <Button sx={{ width: '33%' }} color="inherit">
                                <Stack sx={{ justifyContent: 'center' }}>
                                    <Typography variant="caption" sx={{ alignSelf: 'center' }}>總推薦</Typography>
                                    <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{result.meta.total}顆</Typography>
                                </Stack>
                            </Button>
                            <Button sx={{ width: '33%' }} color="inherit">
                                <Stack sx={{ justifyContent: 'center' }}>
                                    <Typography variant="caption" sx={{ alignSelf: 'center' }}>中號</Typography>
                                    <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}  >{result.meta.winc}顆</Typography>
                                </Stack>
                            </Button>
                            <Button sx={{ width: '33%' }} color="inherit">
                                <Stack sx={{ justifyContent: 'center' }}>
                                    <Typography variant="caption" sx={{ alignSelf: 'center' }}>勝率%</Typography>
                                    <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}  >{result.meta.total === 0 ? "-" : ((result.meta.winc * 100) / result.meta.total).toFixed(2)}</Typography>
                                </Stack>
                            </Button>
                        </ButtonGroup>
                    </Box>}

                    <Divider variant="fullWidth" sx={{ mt: 0, mb: 1 }}></Divider>

                    <TableContainer component={Paper} sx={{}}  >
                        <Table sx={{}} aria-label="customized table" size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">期數</StyledTableCell>
                                    <StyledTableCell align="left">狀態</StyledTableCell>
                                    <StyledTableCell align="left">號碼</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result != null && result.data.map((row) => (
                                    <StyledTableRow key={row.game.index}>
                                        <StyledTableCell align="left">
                                            {row.game.index}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                                            {row.win_count === -1 ? <Button color="inherit" disableElevation size="medium" sx={{ width: 100 }} variant="outlined">尚未開獎</Button> : <Button color="inherit" disableElevation size="medium" sx={{ width: 100 }} variant="outlined">{row.game.guess.length}中{row.win_count}</Button>}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Stack direction="row" spacing={0.5}>
                                                {row.game.guess.map((top) => (
                                                    getColor(row, top) ? <Button key={top} variant="contained" size="large" disableElevation color="error">{top}</Button>:<Button key={top} variant="contained" size="large" disableElevation  color="secondary">{top}</Button> 
                                                    // getColor(row, top) ?
                                                    //     <Avatar key={top} sx={{ bgcolor: red[300] }} variant="circle" >{top}</Avatar> :
                                                    //     <Avatar key={top} sx={{ bgcolor: green[300] }} variant="circle" >{top}</Avatar>
                                                ))}
                                            </Stack>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* </Box> */}

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
