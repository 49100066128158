import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, deepPurple } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Height } from '../util/ScreenHeight'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function WinnerMain(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const fetchData = () => {

        let param = {
            key: age,
            tp: props.tp
        }

        fetch("/lucky/v1/big/winner", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }


    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        fetchData()
    }, [age]);


    return (
        <div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "hidden" }}>
            <Box sx={{ minWidth: 120, m: 1 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">地區</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="地區"
                        onChange={handleChange}
                    >
                        <MenuItem value={""}>全部</MenuItem>
                        <MenuItem value={"基隆"}>基隆</MenuItem>
                        <MenuItem value={"台北"}>台北</MenuItem>
                        <MenuItem value={"新北"}>新北</MenuItem>
                        <MenuItem value={"桃園"}>桃園</MenuItem>
                        <MenuItem value={"台中"}>台中</MenuItem>
                        <MenuItem value={"台南"}>台南</MenuItem>
                        <MenuItem value={"高雄"}>高雄</MenuItem>
                        <MenuItem value={"新竹"}>新竹</MenuItem>
                        <MenuItem value={"彰化"}>彰化</MenuItem>
                        <MenuItem value={"嘉義"}>嘉義</MenuItem>
                        <MenuItem value={"南投"}>南投</MenuItem>
                        <MenuItem value={"花蓮"}>花蓮</MenuItem>
                        <MenuItem value={"苗栗"}>苗栗</MenuItem>
                        <MenuItem value={"雲林"}>雲林</MenuItem>
                        <MenuItem value={"屏東"}>屏東</MenuItem>
                        <MenuItem value={"宜蘭"}>宜蘭</MenuItem>
                        <MenuItem value={"花蓮"}>花蓮</MenuItem>
                        <MenuItem value={"台東"}>台東</MenuItem>
                        <MenuItem value={"金門"}>金門</MenuItem>
                    </Select>
                </FormControl>
            </Box>


            {result != null && <div style={{ width: "100%", position: 'absolute', top: Height.subheader + 20, bottom: 0, overflowY: "scroll" }}>
                <Box sx={{  ml: 1, mb: 1, mr: 1 }}>
                    <TableContainer component={Paper} sx={{}}>
                        <Table sx={{}} aria-label="customized table" size="small">
                            <TableHead>
                                <TableRow>
                                    {/* <StyledTableCell align="center">最近一次日期</StyledTableCell> */}
                                    <StyledTableCell align="left">頭獎次數</StyledTableCell>
                                    <StyledTableCell align="left">店名</StyledTableCell>
                                    <StyledTableCell align="left">地址</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.map((row) => (
                                    <StyledTableRow key={Math.random().toString()}>
                                        {/* <StyledTableCell align="center" component="th" scope="row">
                                    <Button color="success" disableElevation size="medium" sx={{ fontSize: 18, width: 120 }} variant="contained">{row.ymd}</Button>
                                </StyledTableCell> */}
                                        <StyledTableCell align="left">
                                            <Avatar sx={{ width: 48, height: 48, bgcolor: red[300] }} variant="square" >{row.count}</Avatar>
                                            {/* <Button color="success" disableElevation size="medium" sx={{ fontSize: 18 }} variant="outlined">{row.count}</Button> */}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.store}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.address}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>}
        </div>
    );
}
