import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Height } from '../util/ScreenHeight'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CollectItem from './CollectItem';
import RuleDetail from './RuleDetail';
import InputDetail from './InputDialog';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function CollectContainer(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [result, setResult] = React.useState(null);
    const [openRec, setOpenRec] = React.useState(false);
    const [openInput, setOpenInput] = React.useState(false)
    const [gid, setGid] = React.useState(null)
    const [row, setRow] = React.useState(null)

    const fetchData = () => {
        let param = {
        }


        fetch("/lucky/v1/collect/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, []);

    const openIn = (gg, rr) => {
        setGid(gg)
        setRow(rr)
        setOpenInput(true)
    }

    return (
        <div style={{ width: "100%", position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "scroll" }}>
            {openRec ? <RuleDetail open={openRec} close={setOpenRec}></RuleDetail> : null}
            {openInput ? <InputDetail open={openInput} close={setOpenInput} gid={gid} row={row}></InputDetail> : null}

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    {result != null && result.map(row =>
                        <Grid key={row.data.id} item xs={12} sm={6} md={6} lg={4}>
                            <CollectItem row={row} setOpenRec={setOpenRec} openIn={openIn}/>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </div>
    );
}
