import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, deepPurple } from '@mui/material/colors';
import Button from '@mui/material/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 18,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function BigTable4(props) {
    return (
        <div style={{marginRight: 10}}>
            {props.data != null && <TableContainer component={Paper} >
                <Table sx={{  }} aria-label="customized table" size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center"  >號碼</StyledTableCell>
                            <StyledTableCell align="center">{props.title2}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {/* props.result.data.filter(d => d.c > props.result.avg) */}
                        {props.data.map((row) => (
                            <StyledTableRow key={row.n}>
                                <StyledTableCell align="center" component="th" scope="row">
                                    {/* <Avatar sx={{width: 48, height:48, bgcolor: red[300]}}   variant="square" >{row.n}</Avatar> */}
                                    <Button  color="error" disableElevation size="medium" sx={{ }} variant="contained">{row.n}</Button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                <Button color="secondary" disableElevation size="medium" sx={{  }} variant="contained">{row.c}</Button>
                                    </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </div>
    );
}
