import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Stack } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { WindowSharp } from '@mui/icons-material';


import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            '&.Mui-selected': { backgroundColor: "#000000" },
        },
    },
};

export default function UserSign(props) {
    const [open, setOpen] = React.useState(false);
    const [num1, setNum1] = React.useState('');
    const [num2, setNum2] = React.useState('');
    const [num3, setNum3] = React.useState('');
    const [num4, setNum4] = React.useState('');
    const [num5, setNum5] = React.useState('');
    const [num6, setNum6] = React.useState('');

    const [name, setName] = React.useState(localStorage.getItem("name") != null ? localStorage.getItem("name") : "");
    const [code, setCode] = React.useState(localStorage.getItem("code") != null ? localStorage.getItem("code") : "");

    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const handleOKClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };
    
    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    // name : String, code : String, tp : Int, guess : List[Int]

    const input = () => {
        if (name === "" || code === "") {
            alert('需輸入帳號密碼');
        // } else if (num1 === "" || num2 === "" || num3 === "" || num4 === "" || num5 === "" || num6 === "") {
        //     alert('請輸入6個號碼');
        } else if (personName.length != getLuckyNum()) {
            alert('請正確輸入' + getLuckyNum() + '個號碼');
        } else {
            let param = {
                name: name,
                code: code,
                tp: props.tp,
                guess: personName
            }

            fetch("/lucky/v1/big/inrec", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status === -4) {
                                alert("一期只能推薦一次。")
                            } else if (result.status === -3 || result.status === -1) {
                                alert("帳戶已存在或密碼錯誤")
                            } else if (result.status === -2) {
                                alert("系統發生錯誤，請稍後再嘗試。")
                            } else if (result.status === 0) {
                                localStorage.setItem("name", name)
                                localStorage.setItem("code", code)
                                localStorage.setItem("uid", result.uid)
                                handleOKClose()
                            }
                        }
                    }
                );
        }
    }

    const getMaxNum = () => {
        if(props.tp != null) {
            if(props.tp === 0) return 49
            else if(props.tp === 1) return 39
            else if(props.tp === 2) return 38
            else if(props.tp === 3) return 24
            else return 49
        } else return 49
    }
    
    const getLuckyNum = () => {
        if(props.tp != null) {
            if(props.tp === 0) return 6
            else if(props.tp === 1) return 5
            else if(props.tp === 2) return 6
            else if(props.tp === 3) return 12
            else return 6
        } else return 6
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth >
                <DialogTitle>推薦號碼</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    請從1-{getMaxNum()}數字中選擇{getLuckyNum()}個數字來做推薦，每一期只能夠推薦一次。第一次推薦需要取個專屬暱稱與設定密碼。
                    </DialogContentText>
                    {localStorage.getItem("name") == null && <TextField
                        focused
                        autoFocus
                        margin="dense"
                        id="name"
                        label="暱稱"
                        fullWidth
                        variant="standard"
                        helperText="暱稱會公開且不得與人相同"
                        value={name}
                        onChange={event => setName(event.target.value)}
                    />}
                    {localStorage.getItem("code") == null && <TextField
                        focused
                        autoFocus
                        margin="dense"
                        id="pass"
                        type="password"
                        label="密碼"
                        fullWidth
                        variant="standard"
                        helperText="密碼忘記無法補發"
                        value={code}
                        onChange={event => setCode(event.target.value)}
                    />}

                    {/* <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }}></Divider> */}

                    <FormControl  sx={{ mt: 4, mb: 4　}} fullWidth focused>
                        <InputLabel id="demo-multiple-chip-label">號碼</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {[...Array(getMaxNum()).keys()].map(index => {
                                return <MenuItem  key={index + 1} value={index + 1}>{index + 1}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                   


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={input}>確定</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
