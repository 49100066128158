import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Lightbulb } from '@mui/icons-material';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import Slider from '@mui/material/Slider';
import ButtonGroup from '@mui/material/ButtonGroup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';

export default function BaseItem(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const [openHighLow, setOpenHighLow] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);

    const getStatus = () => {
        if (props.row != null && props.row.data != null) {
            if (props.row.data.status === 0) return "未開始"
            else if (props.row.data.status === 1) return "集資中"
            else return ""
        } else return ""
    }

    const getColor = (num) => {
        if (props.row != null) {
            if (props.row.num.includes(num))
                return "error"
            else return "secondary"
        } else return "secondary"
    }

    const getChineseMoney = (money) => {
        if(money >= 10000) return (money / 10000).toFixed(1) + "萬"
        else if(money >= 1000) return (money / 1000).toFixed(1) + "千"
        else return money
    }


    return (
        <>
            {/* <Stack>
               <Typography flexGrow={1} variant="h5" fontWeight={"bold"}>大樂透</Typography>
           </Stack> */}

            <Box display={'flex'}>
                <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 1, pt: 0.5, alignSelf: 'center' }} >
                    {/* <img style={{width: 100, height: 50}} src={"logo_3.webp"}  /> */}
                    <Typography sx={{ fontSize: 28, fontWeight: 'bold', alignSelf: 'center' }} >{props.row.data.title}</Typography>
                    {/* <Typography variant="caption" sx={{ fontWeight: 'normal' }} onClick={e => setOpen(true)}>{"BB"}</Typography> */}
                </Stack>

                <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }}>
                    {/* <Typography color={"red"} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{"CCC"}</Typography> */}
                    {/* <Typography color={"red"} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{""}</Typography> */}
                    
                    {props.row.data.periodi != null && <Button disableElevation size="small" variant="outlined" color="warning">開獎{props.row.data.periodi}</Button>}
                    <Button disableElevation size="small" variant="outlined" color="error">編號{props.row.data.id}</Button>
                    {/* <Button disableElevation size="small" variant="contained" color="secondary">集資中</Button> */}
                </Stack>
            </Box>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />
            <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                <Button sx={{ width: '33%' }} color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>目標</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold' }}>{props.row.data.wint}</Typography>
                    </Stack>

                </Button>
                <Button sx={{ width: '33%' }} color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Stack direction={"row"} sx={{ alignSelf: 'center' }}>
                            <Typography variant="caption" >策略
                            </Typography>
                            {/* <HelpOutlineOutlinedIcon sx={{ width: 16, height: 16 }} /> */}
                        </Stack>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold' }} >中{props.row.data.wv}保{props.row.data.gv}</Typography>
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }} color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>包牌數量</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold', }}  >{props.row.data.num}個</Typography>
                    </Stack></Button>
            </ButtonGroup>
            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />

            <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                <Button sx={{ width: '33%' }} color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>目標注數</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold', }}  >{props.row.data.inpt}</Typography>
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }} color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>現在注數</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold' }} >{props.row.lr.ct}</Typography>
                    </Stack>
                </Button>

                <Button sx={{ width: '33%' }} color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>參與人數</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold' }}>{props.row.lr.join}人</Typography>
                    </Stack>

                </Button>
            </ButtonGroup>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />

            <ButtonGroup color="inherit" variant="text" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                <Button sx={{ width: '33%' }} color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>目標金額(元)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold' }}>{getChineseMoney(props.row.data.inpm)}</Typography>
                    </Stack>

                </Button>
                <Button sx={{ width: '33%' }} color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>現在金額(元)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold' }} >{getChineseMoney(props.row.lr.cm)}</Typography>
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }} color="inherit">
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center' }}>完成度%</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 28, fontWeight: 'bold', }}  >{props.row.lr.complete}%</Typography>
                    </Stack></Button>
            </ButtonGroup>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />


            

        </>

    );
}
