import React, { useState, useEffect, useRef } from 'react';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Height } from '../util/ScreenHeight'
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import AITable1 from './AITable1'


export default function OLSDetail(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



    const fetchData = () => {
        let tp = 2
        if(props.tp === 1) tp = 5
        else if(props.tp === 2) tp = 12
        else if(props.tp === 3) tp = 17
        
        let param = {
            tp: tp
        }

        let lic = localStorage.getItem("lic")
        if(lic != null) param["lic"] = lic

        fetch("/lucky/v1/deeplearning/ai", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }


    useEffect(() => {
        fetchData()
    }, []);


    return (
        <div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "scroll" }}>

            <Box sx={{ mt: 1, ml: 1, mb: 1 }}>
                <Typography variant='h6' sx={{ fontWeight: "bold" }}>
                    使用策略
                </Typography>

                <Typography variant='body1' sx={{ mr: 2 }}>
                線性回歸模型，利用OLS來做預測分析
                </Typography>

                <Stack direction={"row"} spacing={0.5} sx={{ mt: 4, mb: 0.5 }}>
                    <Typography variant='h6' sx={{ alignSelf: "end", fontWeight: "bold" }}>
                        預測結果
                    </Typography>
                </Stack>
                <AITable1 result={result} tp={props.tp}/>

            </Box>
        </div>
    );
}

