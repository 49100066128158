import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, deepPurple } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 18,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function BigTable71(props) {
    return (
        <div style={{ marginRight: 10, marginTop: 10 }}>
            {props.result != null && <TableContainer component={Paper} >
                <Table sx={{}} aria-label="customized table" size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">{props.title}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.result.map((row) => (
                            <StyledTableRow key={Math.random().toString()}>
                                <StyledTableCell align="left">
                                    <Typography variant="caption">{row.num} </Typography>
                                    <Stack direction={"row"} spacing={0.8}>
                                        <Button key={row.n1} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n1}</Button>
                                        <Button key={row.n2} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n2}</Button>
                                        <Button key={row.n3} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n3}</Button>
                                        <Button key={row.n4} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n4}</Button>
                                        <Button key={row.n5} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n5}</Button>
                                        {row.n6 != null && row.n6 != 0 && <Button key={row.n6} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n6}</Button>}
                                        {row.n7 != null && row.n7 != 0 && <Button key={row.n7} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n7}</Button>}
                                        {row.n8 != null && row.n8 != 0 && <Button key={row.n8} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n8}</Button>}
                                        {row.n9 != null && row.n9 != 0 && <Button key={row.n9} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n9}</Button>}
                                        {row.n10 != null && row.n10 != 0 && <Button key={row.n10} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n10}</Button>}
                                        {row.n11 != null && row.n11 != 0 && <Button key={row.n11} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n11}</Button>}
                                        {row.n12 != null && row.n12 != 0 && <Button key={row.n12} color="secondary" disableElevation size="large" sx={{}} variant="contained">{row.n12}</Button>}
                                    </Stack>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </div>
    );
}
