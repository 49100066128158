import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green } from '@mui/material/colors';
import { Box } from '@mui/system';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { Divider, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function WithDraw(props) {
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState(null);
    const [price, setPrice] = React.useState('p2')
    const [personName, setPersonName] = React.useState([]);
    const [openRec, setOpenRec] = React.useState(false);
    const [bank, setBank] = React.useState(localStorage.getItem("COL_BANK") != null ? localStorage.getItem("COL_BANK") : "")
    const [bbank, setBBank] = React.useState(localStorage.getItem("COL_BBANK") != null ? localStorage.getItem("COL_BBANK") : "")
    const [account, setAccount] = React.useState(localStorage.getItem("COL_BANK_ACCOUNT") != null ? localStorage.getItem("COL_BANK_ACCOUNT") : "")
    const [money, setMoney] = React.useState("")


    const [checked, setChecked] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const banklist = ["001	中央信託",
        "003	交通銀行",
        "004	台灣銀行",
        "005	土地銀行",
        "006	合庫商銀",
        "007	第一銀行",
        "008	華南銀行",
        "009	彰化銀行",
        "010	華僑銀行",
        "011	上海銀行",
        "012	台北富邦",
        "013	國泰世華",
        "016	高雄銀行",
        "017	兆豐商銀",
        "018	農業金庫",
        "021	花旗銀行",
        "024	運通銀行",
        "025	首都銀行",
        "039	荷蘭銀行",
        "040	中華開發",
        "050	臺灣企銀",
        "051	台北商銀",
        "052	新竹商銀",
        "053	台中商銀",
        "054	京城商銀",
        "056	花蓮企銀",
        "057	台東企銀",
        "075	東亞銀行",
        "081	匯豐銀行",
        "083	渣打銀行",
        "087	標旗銀行",
        "102	華泰銀行",
        "103	臺灣新光商銀",
        "108	陽信銀行",
        "118	板信銀行",
        "147	三信銀行",
        "151	第七商銀",
        "700	中華郵政",
        "803	聯邦銀行",
        "804	中華銀行",
        "805	遠東銀行",
        "806	復華銀行",
        "807	建華銀行",
        "808	玉山銀行",
        "809	萬泰銀行",
        "810	寶華銀行",
        "812	台新銀行",
        "814	大眾銀行",
        "815	日盛銀行",
        "816	安泰銀行",
        "822	中國信託",
        "825	慶豐銀行"]

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const handleOKClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) {
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);


    const inputReq = () => {
        let uid = localStorage.getItem("COL_UID")
        let email = localStorage.getItem("COL_EMAIL")
        let phone = localStorage.getItem("COL_PHONE")

        if (email == null || phone == null || uid == null) {
            alert("請重新登入")
        } else if (bank === "" || bbank === "" || money === "") {
            alert("請確實填寫銀行資訊或金額")
        } else if (parseInt(money) > props.money) {
            alert("提款金額超過額度")
        } else if (parseInt(money) < 20) {
            alert("提款金額不足手續費")
        } else {
            let param = {
                email: email,
                phone: phone,
                uid : uid,
                bank: bank,
                bbank: bbank,
                account: account,
                money: money
            }

            fetch("/lucky/v1/money/withdraw", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status === -1) {
                                alert("登入資訊有問題，請重新確認電子信箱與電話是否匹配。")
                                localStorage.removeItem("COL_UID")
                                localStorage.removeItem("COL_EMAIL")
                                localStorage.removeItem("COL_PHONE")
                            } else if(result.status === 0){
                                alert("提領申請已成功送出，工作時間需3-5工作日，請耐心等候。")
                                handleOKClose()
                            }
                        }
                    }
                );

        }
    }



    return (
        <div>
            <Dialog
                // fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"提領"}
                </DialogTitle>
                <Divider />

                <DialogContent sx={{}}>
                    <Typography variant='h6' >帳戶餘額：{props.money}元</Typography>

                    <TextField
                        value={bank}
                        onChange={e => setBank(e.target.value)}
                        autoFocus
                        focused
                        margin="dense"
                        id="bank"
                        label="銀行名稱"
                        fullWidth
                        variant="standard"
                        select
                    >
                        {banklist.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        value={bbank}
                        onChange={e => setBBank(e.target.value)}
                        autoFocus
                        focused
                        margin="dense"
                        id="bbank"
                        label="分行名稱"
                        fullWidth
                        variant="standard"
                    />

                    <TextField
                        value={account}
                        onChange={e => setAccount(e.target.value)}
                        autoFocus
                        focused
                        margin="dense"
                        id="account"
                        label="帳號"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        value={money}
                        onChange={e => setMoney(e.target.value)}
                        autoFocus
                        focused
                        type="number"
                        margin="dense"
                        id="money"
                        label="提領金額"
                        fullWidth
                        variant="standard"
                    />
                    <Stack>
                        <Typography variant="subtitle2">每次提領都需要扣20元手續費</Typography>
                        <Typography variant="subtitle2">作業時間需要3-5個工作日</Typography>
                    </Stack>
                </DialogContent>



                <DialogActions>
                    <Button fullWidth sx={{}} variant="contained" onClick={handleClose} autoFocus>
                        取消
                    </Button>
                    <Button fullWidth sx={{}} variant="contained" onClick={inputReq} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
