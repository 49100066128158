import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import CheckContainer from './CheckContainer';
import WinnerMain from './WinnerMain'

export default function CheckMain(props) {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                // maxWidth: { xs: 320, sm: 480 },
                // bgcolor: 'background.paper',
                bgcolor: theme.palette.mode === "light"?"#f2f0f0":"#595959"
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                <Tab label="開獎號碼" />
                <Tab label="排名100頭獎投注站" />
            </Tabs>

            {value === 0 && <CheckContainer tp={props.tp}/>}
            {value === 1 && <WinnerMain tp={props.tp} />}          
        </Box>
    );
}
