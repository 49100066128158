import logo from '../logo.svg';
import '../App.css';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Divider, Typography } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import SendIcon from '@mui/icons-material/Send';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import BigLucky from '../main/BigLucky';
import BigLucky539 from '../main/BigLucky539'
import CollectMain from '../collect/CollectMain';
import PaymentContainer from '../payment/PaymentContainer'
import ViewCompact from '@mui/icons-material/ViewCompact'
import BigLuckyPower from '../main/BigLuckyPower';
import BigLucky24 from '../main/BigLucky24';
import Warehouse from '@mui/icons-material/Warehouse';

function CHome(props) {
    const [value, setValue] = React.useState(0);

    const showScreen = () => {
        if (value === 0) {
            return <div className="App">
                <header className="App-header">

                    <img style={{ width: 80 }} src={"money.png"} />
                    <Typography sx={{ mt: 3 }} variant="h3">樂透挖土機</Typography>
                    <Typography sx={{ mt: 0 }} variant="caption">統計、AI預測、分享號碼、包牌的台灣樂透工具</Typography>
                    
                    <Stack direction={"row"} spacing={0.5} sx={{ mt: 3 }}>
                        <Button disableElevation variant='contained' color="error" sx={{ width: 120 }} startIcon={<AccessTimeIcon />} onClick={e => setValue(1)} >大樂透</Button>
                        <Button disableElevation variant='contained' color="error" sx={{ width: 120 }} startIcon={<AdUnitsIcon />} onClick={e => setValue(3)} >今彩539</Button>
                        <Button disableElevation variant='contained' color="error" sx={{ width: 120 }} startIcon={<SendIcon />} onClick={e => setValue(5)}>威力彩</Button>
                    </Stack>
                    <Stack direction={"row"} spacing={0.5} sx={{ mt: 0.5 }}>
                        <Button disableElevation variant='contained' color="warning" sx={{ width: 368 }} startIcon={<Warehouse />} onClick={e => setValue(6)}>雙贏彩</Button>
                    </Stack>

                    {/* <Divider sx={{ mt: 2, mb: 2, fontSize: 18 }}>或</Divider>
                    <Stack direction={"row"} spacing={0.5} sx={{ mt: 1 }}>
                        <Button disableElevation variant='contained' color="info" sx={{ width: 368 }} startIcon={<ViewCompact />} onClick={e => setValue(4)}>成為VIP</Button>
                    </Stack> */}

                    
                    
                    {/* <ButtonGroup sx={{ mt: 3 }} variant="contained" aria-label="outlined primary button group">
                        <Button color="error" startIcon={<AccessTimeIcon />} onClick={e => setValue(1)} >大樂透</Button>
                        <Button color="error" startIcon={<AdUnitsIcon />} onClick={e => setValue(3)} >今彩539</Button>
                    </ButtonGroup>

                    <ButtonGroup sx={{ mt: 3 }} variant="contained" aria-label="outlined primary button group">
                        <Button color="error" startIcon={<SendIcon />} onClick={e => setValue(5)} >威力彩</Button>
                        <Button color="error" startIcon={<Warehouse />} onClick={e => setValue(6)} >雙贏彩</Button>
                    </ButtonGroup>

                    <Divider sx={{ mt: 2, mb: 2, fontSize: 18 }}>或</Divider>
                    <ButtonGroup sx={{ mt: 0 }} variant="contained" aria-label="outlined primary button group">
                        <Button disableElevation variant='contained' sx={{ width: 310 }} color="warning" startIcon={<MonetizationOn />} onClick={e => setValue(2)} >集資</Button>
                        <Button disableElevation variant='contained' sx={{ width: 155 }} color="info" startIcon={<ViewCompact />} onClick={e => setValue(4)} >成為VIP</Button>
                    </ButtonGroup> */}
                    
                    {/* <Divider sx={{ mt: 2, mb: 2, fontSize: 18 }}>或</Divider>
                    <Button disableElevation variant='contained' sx={{ width: 310 }} color="error" startIcon={<MonetizationOn />} onClick={e => setValue(2)} >集資</Button> */}
                </header>
            </div>
        } else if(value === 1) {
            return <BigLucky goto={setValue} from={"google"}/>
        } else if(value === 2) {
            return <CollectMain goto={setValue} from={"google"}/>
        } else if (value === 3) {
            return <BigLucky539 goto={setValue} from={"google"}/>
        } else if (value === 4) {
            return <PaymentContainer goto={setValue} from={"google"}/>
        } else if (value === 5) {
            return <BigLuckyPower goto={setValue} from={"google"}/>
        } else if (value === 6) {
            return <BigLucky24 goto={setValue} from={"google"}/>
        } 
    }

    return (
        <>{showScreen()}</>
    );
}

export default CHome;
