import React, { useState, useEffect, useRef } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, ScatterChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import BigTable2 from './BigTable2'
import { Height } from '../util/ScreenHeight'
import BigSetting1 from './BigSetting1';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

echarts.use(
    [ScatterChart, TitleComponent, TooltipComponent, GridComponent, LineChart, BarChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


export default function BigDetail2(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



    function genoption1() {
        let k = {
            animation: false,
            grid: [{
                top: 30,
                left: 10,
                right: 20,
                height: 300,
                containLabel: true
            }],
            legend: {
                show: false
            },
            yAxis: [
                {
                    scale: true,
                    type: 'value',
                    // position: 'left',
                    show: true,
                    // splitLine: {
                    //     show: true,
                    //     lineStyle: {
                    //         opacity: 0.2
                    //     }
                    // },
                    // axisLabel: {
                    //     inside: true,
                    //     margin: 0
                    // },                    
                }],
            series: [
                {
                    name: '開獎次數',
                    type: 'bar',
                    data: result.data.map(v => { return v.c }),
                    selectedMode: 'single',
                    markLine: {
                        symbol: "none",
                        label: {
                            show: true,
                            position: "insideStartTop",
                            formatter: '{b}',
                            fontWeight: "bold"
                        },
                        lineStyle: {
                            color: "red",
                            type: 'dashed'
                        },
                        data: [
                            {
                                name: '平均線',
                                yAxis: result.avg,
                                lineStyle: {
                                    width: 3,
                                }
                            }
                        ]
                    },
                }
            ]
        };

        let x1 = {
            type: 'category',
            boundaryGap: false,
            data: result.data.map(v => v.n)
        }

        if (fullScreen) {
            k['tooltip'] = getMobileTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            k['tooltip'] = getComputerTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getComputerDataZoom(0, 100, 470)
        }

        k['xAxis'] = [x1]
        return k;
    }

    const fetchData = () => {
        let period = localStorage.getItem('search_light') != null ? localStorage.getItem('search_light') : '0'
        let count = localStorage.getItem('search_count') != null ? localStorage.getItem('search_count') : '0'

        let param = {
            tp: props.tp
        }

        if (count != "0") param["count"] = count
        if (period != "0") param["period"] = period
        if (localStorage.getItem("lic") != null) {
            param['lic'] = localStorage.getItem("lic")
        }
        fetch("/lucky/v1/big/detail2", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }

    // useEffect(() => {
    //     if (result != null) setOption(genoption1)
    // }, [result]);

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "scroll" }}>
            {openSetting ? <BigSetting1 refresh={fetchData} open={openSetting} close={setOpenSetting}></BigSetting1> : null}

            <Box sx={{ mt: 1, ml: 1, mb: 1 }}>
                <Typography variant='h6' sx={{ fontWeight: "bold" }}>
                    使用策略
                </Typography>

                <Typography variant='body1' sx={{ mr: 2 }}>
                    統計上期某個號碼帶出下期號碼出現機率，每個數字選出下期最多重複出現的前三名。
                </Typography>

                <Stack direction={"row"} spacing={0.5} sx={{ mt: 4, mb: 0.5}}>
                    <Typography variant='h6' sx={{ alignSelf: "end", fontWeight: "bold" }}>
                        統計分析
                    </Typography>
                    <Button onClick={e => setOpenSetting(true)} color="secondary" variant="contained" size="small" disableElevation>設定期數</Button>
                </Stack>

                {/* {result != null && <BigTable2 result={result.data} title1="號碼" title2="拖牌TOP3" />} */}
                
                {result != null && <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                    <BigTable2 result={result.data.slice(0, props.middle)} title1="號碼" title2="拖牌TOP3" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <BigTable2 result={result.data.slice(props.middle, 49)} title1="號碼" title2="拖牌TOP3" />
                    </Grid>
                </Grid>}

                <Typography variant='h6' sx={{ mt: 4, fontWeight: "bold" }}>
                    最後結果
                </Typography>
                <Typography variant='body1' sx={{ mr: 2 }}>
                    依據最新一期號碼所選下期TOP3號碼
                </Typography>

                {result != null && <BigTable2 result={result.rec} title1="號碼" title2="拖牌TOP3" />}
            </Box>
        </div>
    );
}

