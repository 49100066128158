import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BigDetail1 from '../detail/BigDetail1';
import BigDetail2 from '../detail/BigDetail2';
import BigDetail3 from '../detail/BigDetail3';
import BigDetail4 from '../detail/BigDetail4'
import BigDetail5 from '../detail/BigDetail5';
import BigDetail6 from '../detail/BigDetail6';
import BigDetail7 from '../detail/BigDetail7';
import { useTheme } from '@mui/material/styles';

export default function BigMain(props) {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getMiddle = () => {
        if(props.tp === 0) return 25
        else if(props.tp === 1) return 20
        else if(props.tp === 2) return 19
        else if(props.tp === 3) return 12
        else return 25
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                // maxWidth: { xs: 320, sm: 480 },
                // bgcolor: 'background.paper',
                bgcolor: theme.palette.mode === "light"?"#f2f0f0":"#595959"
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                <Tab label="出現" />
                <Tab label="拖牌" />
                <Tab label="相鄰" />
                <Tab label="遺漏" />
                <Tab label="頭尾" />
                <Tab label="尾數" />
                <Tab label="和值" />
            </Tabs>

            {value === 0 && <BigDetail1 tp={props.tp} middle={getMiddle()}/>}
            {value === 1 && <BigDetail2 tp={props.tp} middle={getMiddle()}/>}
            {value === 2 && <BigDetail3 tp={props.tp} middle={getMiddle()}/>}
            {value === 3 && <BigDetail4 tp={props.tp} middle={getMiddle()}/>}
            {value === 4 && <BigDetail5 tp={props.tp} middle={getMiddle()}/>}  
            {value === 5 && <BigDetail6 tp={props.tp} middle={getMiddle()}/>} 
            {value === 6 && <BigDetail7 tp={props.tp} middle={getMiddle()}/>}           
        </Box>
    );
}
