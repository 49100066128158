import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, deepPurple } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function AITable1(props) {

    const isWinColor = (win) => {
        if(props.tp != 3) {
            return win > 0
        } else {
            return (win === 0 || win === 1 || win === 2 || win === 12 || win === 11 || win === 10)
        }
    }

    return (
        <div style={{ marginRight: 10, marginTop: 10 }}>
            {props.result != null && <TableContainer component={Paper} >
                <Table sx={{}} aria-label="customized table" size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">本期</StyledTableCell>
                            <StyledTableCell align="left">下期結果</StyledTableCell>
                            <StyledTableCell align="left">下期預測</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.result.map((row) => (
                            <StyledTableRow key={Math.random().toString()}>
                                <StyledTableCell align="left">
                                    {row.big.num}
                                </StyledTableCell>

                                {row.win != null ? <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                                    <Button color={isWinColor(row.win) ? "error" : "secondary"} disableElevation size="large" sx={{  }} variant="contained">中{row.win}星</Button>
                                </StyledTableCell> : <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                                    <Button color="secondary" disableElevation size="large" sx={{  }} variant="contained">尚未開獎</Button>
                                </StyledTableCell>}
                                <StyledTableCell align="center">
                                    {localStorage.getItem("noad") === "1" && <Stack direction={"row"} spacing={0.8}>
                                        {row.pdic.map((top) => (
                                            <Button key={top} color={row.winn.includes(top) ? "error" : "secondary"} disableElevation size="large" sx={{  }} variant="contained">{top}</Button>
                                            
                                        ))}
                                    </Stack>}
                                    {localStorage.getItem("noad") != "1" && row.win != null && <Stack direction={"row"} spacing={0.8}>
                                        {row.pdic.map((top) => (
                                            <Button key={top} color={row.winn.includes(top) ? "error" : "secondary"} disableElevation size="large" sx={{  }} variant="contained">{top}</Button>
                                            
                                        ))}
                                    </Stack>}

                                    {localStorage.getItem("noad") != "1" && row.win == null &&<Stack direction={"row"} spacing={0.8}>
                                            <Button key={"1"} color="secondary" disableElevation size="large" sx={{   }} fullWidth variant="contained">VIP專屬</Button>
                                        </Stack>
                                    }
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </div>
    );
}
