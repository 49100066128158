import React, { useState, useEffect, useRef } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, ScatterChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Height } from '../util/ScreenHeight'
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import AITable1 from './AITable1'

echarts.use(
    [ScatterChart, TitleComponent, TooltipComponent, GridComponent, LineChart, BarChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


export default function LSTMDetail(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



    function genoption1() {
        let k = {
            animation: false,
            grid: [{
                top: 30,
                left: 10,
                right: 20,
                height: 300,
                containLabel: true
            }],
            legend: {
                show: false
            },
            yAxis: [
                {
                    scale: true,
                    type: 'value',
                    // position: 'left',
                    show: true,
                    // splitLine: {
                    //     show: true,
                    //     lineStyle: {
                    //         opacity: 0.2
                    //     }
                    // },
                    // axisLabel: {
                    //     inside: true,
                    //     margin: 0
                    // },                    
                }],
            series: [
                {
                    name: '開獎次數',
                    type: 'bar',
                    data: result.data.map(v => { return v.c }),
                    selectedMode: 'single',
                    markLine: {
                        symbol: "none",
                        label: {
                            show: true,
                            position: "insideStartTop",
                            formatter: '{b}',
                            fontWeight: "bold"
                        },
                        lineStyle: {
                            color: "red",
                            type: 'dashed'
                        },
                        data: [
                            {
                                name: '平均線',
                                yAxis: result.avg,
                                lineStyle: {
                                    width: 3,
                                }
                            }
                        ]
                    },
                }
            ]
        };

        let x1 = {
            type: 'category',
            boundaryGap: false,
            data: result.data.map(v => v.n)
        }

        if (fullScreen) {
            k['tooltip'] = getMobileTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            k['tooltip'] = getComputerTooltip()
            k['axisPointer'] = getAxisPointer()
            k['dataZoom'] = getComputerDataZoom(0, 100, 470)
        }

        k['xAxis'] = [x1]
        return k;
    }

    const fetchData = () => {
        let tp = 0
        if(props.tp === 1) tp = 7
        else if(props.tp === 2) tp = 10
        else if(props.tp === 3) tp = 15

        let param = {
            tp: tp
        }

        let lic = localStorage.getItem("lic")
        if(lic != null) param["lic"] = lic

        fetch("/lucky/v1/deeplearning/ai", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }

    // useEffect(() => {
    //     if (result != null) setOption(genoption1)
    // }, [result]);

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "scroll" }}>

            <Box sx={{ mt: 1, ml: 1, mb: 1 }}>
                <Typography variant='h6' sx={{ fontWeight: "bold" }}>
                    使用策略
                </Typography>

                <Typography variant='body1' sx={{ mr: 2 }}>
                    利用遞歸神經網路 (RNN) 與長短期記憶模型 (LSTM)來預測出下期開出的數字。
                </Typography>

                <Stack direction={"row"} spacing={0.5} sx={{ mt: 4, mb: 0.5 }}>
                    <Typography variant='h6' sx={{ alignSelf: "end", fontWeight: "bold" }}>
                        預測結果
                    </Typography>
                </Stack>

                {/* {result != null && <BigTable2 result={result.data} title1="號碼" title2="拖牌TOP3" />} */}

                {/* {result != null && <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <AITable1 result={result.slice(0, 10)}  />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <AITable1 result={result.slice(10, 20)}  />
                    </Grid>
                </Grid>} */}
                <AITable1 result={result} tp={props.tp}/>

            </Box>
        </div>
    );
}

