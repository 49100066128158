import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green } from '@mui/material/colors';
import { Box } from '@mui/system';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { Divider, Stack } from '@mui/material';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MoneyDetail(props) {
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState(null);
    const [price, setPrice] = React.useState('p2')
    const [personName, setPersonName] = React.useState([]);
    const [openRec, setOpenRec] = React.useState(false);
    const [email, setEmail] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [checked, setChecked] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const handleOKClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) {
            inputReq()
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);

    const inputReq = () => {
        let param = {
            gid: props.gid
        }

        fetch("/lucky/v1/money/game", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );

    }

    return (
        <div>
            <Dialog
                // fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="responsive-dialog-title">
                    {"獎金分配"}
                </DialogTitle>
                <Divider />

                <DialogContent sx={{}}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 300 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>名稱</TableCell>
                                    <TableCell align="center">數值</TableCell>
                                </TableRow>
                            </TableHead>
                            {result != null && <TableBody>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        中頭獎
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button sx={{ width: 120 }} disableElevation color="inherit" variant="contained">
                                            {result.w1}注
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        中2獎
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.w2}注
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        中3獎
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.w3}注
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        中4獎
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.w4}注
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        中5獎
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.w5}注
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        中6獎
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.w6}注
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        中7獎
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.w7}注
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        中普獎
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.w8}注
                                        </Button>
                                    </TableCell>
                                </TableRow>


                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        總獎金
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.totalm}元
                                        </Button>
                                    </TableCell>
                                </TableRow>

                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        所得稅
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.tax}元
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        印花稅
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.ftax}元
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        服務費
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.my}元
                                        </Button>
                                    </TableCell>
                                </TableRow>

                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        實際獎金
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.rest}元
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        單注獎金
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button disableElevation sx={{ width: 120 }} color="inherit" variant="contained">
                                            {result.unit}元
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>}
                        </Table>
                    </TableContainer>

                </DialogContent>
                <DialogActions>
                    <Button color="error" fullWidth sx={{}} variant="contained" onClick={handleClose} autoFocus>
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
