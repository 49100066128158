import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 18,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function BigTable2(props) {
    return (
        <div style={{ marginRight: 10, marginTop: 10 }}>
            {props.result != null && <TableContainer component={Paper} >
                <Table sx={{}} aria-label="customized table" size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">{props.title1}</StyledTableCell>
                            <StyledTableCell align="left">{props.title2}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.result.map((row) => (
                            <StyledTableRow key={row.n}>
                                <StyledTableCell align="center" component="th" scope="row">
                                    {/* <Avatar sx={{width: 48, height:48, bgcolor: red[300]}}   variant="square" >{row.n}</Avatar> */}
                                    <Button key={row.n} color="error" disableElevation size="large" sx={{ }} variant="contained">{row.n}</Button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack direction={"row"} spacing={0.8}>
                                        {row.top.map((top) => (
                                            <Button key={top} color="secondary" disableElevation size="large" sx={{}} variant="contained">{top}</Button>
                                            // <Avatar sx={{ width: 48, height: 48, bgcolor: red[300] }} variant="rounded" >{top}</Avatar>
                                        ))}
                                    </Stack>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </div>
    );
}
