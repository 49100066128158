import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import UserSign from './UserSign';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { Height } from '../util/ScreenHeight'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WithDraw from './WithDraw';
import MyMoneyList from './MyMoneyList';
import MoneyDetail from './MoneyDetail'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function UserMoney(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openWithDraw, setOpenWithDraw] = React.useState(false);
    const [openMyMoneyList, setOpenMyMoneyList] = React.useState(false);
    const [openMoneyDetail, setOpenMoneyDetail] = React.useState(false);
    const [gid, setGid] = React.useState(null);

    const [openRec, setOpenRec] = React.useState(false);


    const [age, setAge] = React.useState("");
    const [money, setMoney] = React.useState(0);

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const fetchData = () => {
        let uid = localStorage.getItem("COL_UID")
        let email = localStorage.getItem("COL_EMAIL")
        let phone = localStorage.getItem("COL_PHONE")

        if (uid != null && email != null && phone != null) {
            let param = {
                email: email,
                phone: phone
            }

            if (uid != null) param['iuid'] = uid

            fetch("/lucky/v1/winr/list", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status === -1 || result.status === -2) {
                                alert("登入資訊有問題，請重新確認電子信箱與電話是否匹配。")
                                localStorage.removeItem("COL_UID")
                                localStorage.removeItem("COL_EMAIL")
                                localStorage.removeItem("COL_PHONE")
                            } else if (result.status === 0 && result.ic != null) {
                                setResult(result.ic)
                            }

                        }
                    }
                );
        }
    }

    const fetchMoney = () => {
        let uid = localStorage.getItem("COL_UID")
        let email = localStorage.getItem("COL_EMAIL")
        let phone = localStorage.getItem("COL_PHONE")

        if (uid != null && email != null && phone != null) {
            let param = {
                email: email,
                phone: phone
            }

            if (uid != null) param['uid'] = uid

            fetch("/lucky/v1/money/my", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
            .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            setMoney(result.my)
                        }
                    }
                );
        }
    }

    useEffect(() => {
        fetchData()
        fetchMoney()
    }, []);


    const getColor = (row, win) => {
        if (row != null && row.win_list != null) {
            return row.win_list.includes(win)
        } else return false
    }


    const getStatusString = (row) => {
        if (row.status === 0) {
            return row.wmoney + "元"
        } else return "尚未開獎"
    }

    const getStatusColor = (row) => {
        if (row.status === 0) {
            if(row.wmoney > 0)
            return "error"
            else return "inherit"
        } else return "inherit"
    }

    const openMDetail = (gid) => {
        setGid(gid)
        setOpenMoneyDetail(true)
    }

    return (
        <div style={{ marginRight: 0 }}>
            <Box sx={{ minWidth: 120, m: 1 }}>
                {openSetting ? <UserSign refresh={fetchData} open={openSetting} close={setOpenSetting}></UserSign> : null}
                {openWithDraw ? <WithDraw money={money} refresh={fetchMoney} open={openWithDraw} close={setOpenWithDraw}></WithDraw> : null}
                {openMyMoneyList ? <MyMoneyList money={money} open={openMyMoneyList} close={setOpenMyMoneyList}></MyMoneyList> : null}
                {openMoneyDetail ? <MoneyDetail gid={gid} open={openMoneyDetail} close={setOpenMoneyDetail}></MoneyDetail> : null}



                {localStorage.getItem("COL_UID") == null && <Button color="secondary" disableElevation fullWidth variant="contained" startIcon={<AddIcon />} onClick={e => setOpenSetting(true)}>
                    請先登入
                </Button>}

                <Stack direction={"row"} spacing={0.5}>
                {localStorage.getItem("COL_UID") != null && <Button sx={{flexGrow: 1}} color="secondary" disableElevation  variant="contained" onClick={e => setOpenMyMoneyList(true)} >
                    帳戶獎金：NT${money}
                </Button>}
                {localStorage.getItem("COL_UID") != null && <Button color="secondary" disableElevation  variant="contained" onClick={e => setOpenWithDraw(true)}>
                    提領
                </Button>}
                </Stack>
            </Box>

            {<div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "scroll" }}>
                <Box sx={{ ml: 1, mb: 1, mr: 1 }}>
                    <TableContainer component={Paper} sx={{}}  >
                        <Table sx={{}} size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">編號</StyledTableCell>
                                    <StyledTableCell align="left">名稱</StyledTableCell>
                                    <StyledTableCell align="left">完成度</StyledTableCell>
                                    <StyledTableCell align="left">日期</StyledTableCell>
                                    <StyledTableCell align="left">下注金額</StyledTableCell>
                                    <StyledTableCell align="left">注數</StyledTableCell>
                                    <StyledTableCell align="left">獎金</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result != null && result.map((row) => (
                                    <StyledTableRow key={Math.random().toString()}>
                                        <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                                            <Button sx={{ width: 120 }} disableElevation  variant="contained" color="inherit">編號{row.game.data.id}</Button>
                                        </StyledTableCell>

                                        <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                                            <Typography variant='body1' >{row.game.data.title}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Button color="inherit" disableElevation size="medium" sx={{ width: 80 }} variant="outlined">{row.game.lr.complete}%</Button>
                                        </StyledTableCell>
                                        {/* <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                                            {row.status === -1 ? <Button color="inherit" disableElevation size="medium" sx={{ width: 100 }} variant="outlined">尚未開獎</Button> : <Button color="inherit" disableElevation size="medium" sx={{ width: 100 }} variant="outlined">已開獎</Button>}
                                        </StyledTableCell> */}

                                        <StyledTableCell align="left">
                                            <Typography variant='body1'>{row.ymd}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Typography variant='body1'>NT${row.pmoney}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Typography variant='body1'>{row.ind}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Button onClick={e => openMDetail(row.game.data.id)} color={getStatusColor(row)} disableElevation size="medium" sx={{ width: 100 }} variant="contained">{getStatusString(row)}</Button>
                                        </StyledTableCell>

                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>}

        </div>
    );
}
