import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red, green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import UserSign from './UserSign';
import RecItem from './RecItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import RecDialog from './RecDialog';
import { Height } from '../util/ScreenHeight'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function UserRec(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openRec, setOpenRec] = React.useState(false);
    const [openUid, setOpenUid] = React.useState(null);

    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const fetchData = () => {
        let param = {
            key: age,
            tp: props.tp
        }

        fetch("/lucky/v1/big/reclist", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }


    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        fetchData()
    }, [age]);

    const getColor = (row, win) => {
        if (row != null && row.win_list != null) {
            return row.win_list.includes(win)
        } else return false
    }

    const openRecClick = (uidd) => {
        setOpenUid(uidd)
        setOpenRec(true)
    }

    return (
        <div style={{ marginRight: 0 }}>
            <Box sx={{ minWidth: 120, m: 1 }}>
                {openSetting ? <UserSign tp={props.tp} refresh={fetchData} open={openSetting} close={setOpenSetting}></UserSign> : null}
                {openRec ? <RecDialog tp={props.tp} uid={openUid} open={openRec} close={setOpenRec}></RecDialog> : null}

                <Button color="secondary" disableElevation fullWidth variant="contained" startIcon={<AddIcon />} onClick={e => setOpenSetting(true)}>
                    推薦號碼
                </Button>
            </Box>
            {/* <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', overflowY: 'scroll', height: "70vh", }}>
                {result != null && result.map(row => {
                    return <Grid key={row.game.gid} item xs={12} sm={6} md={4} lg={3}>
                        <RecItem row={row}></RecItem>
                    </Grid>
                })}
                {result != null && result.length === 0 &&
                    <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', alignSelf: 'center', m: 1 }}>目前尚未有任何人推薦。</Typography>
                }

            </Grid> */}

            {result != null && <div style={{ width: "100%", position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: "scroll" }}>
                <Box sx={{ ml: 1, mb: 1, mr: 1 }}>
                    <TableContainer component={Paper} sx={{  }}  >
                        <Table sx={{}} aria-label="customized table" size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">暱稱 / 期別</StyledTableCell>
                                    <StyledTableCell align="left">勝率</StyledTableCell>
                                    <StyledTableCell align="left">預測結果</StyledTableCell>
                                    <StyledTableCell align="left">號碼</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.map((row) => (
                                    <StyledTableRow key={Math.random().toString()}>
                                        <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                                            <Stack>
                                            <Typography variant='body1' fontWeight={"bold"}>{row.game.name}</Typography>
                                            <Typography variant='caption'>{row.game.index}</Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Button onClick={e => openRecClick(row.game.uid)} color="error" disableElevation size="medium" sx={{ width: 100 }} variant="contained">{row.meta.total != 0 ? ((row.meta.winc * 100) / row.meta.total).toFixed(2) + "%" : "-"}</Button>

                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                                            {row.win_count === -1 ? <Button onClick={e => openRecClick(row.game.uid)} color="inherit" disableElevation size="medium" sx={{ width: 100 }} variant="outlined">尚未開獎</Button> : <Button onClick={e => openRecClick(row.game.uid)} color="inherit" disableElevation size="medium" sx={{ width: 100 }} variant="outlined">{row.game.guess.length}中{row.win_count}</Button>}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Stack direction="row" spacing={0.5}>
                                                {row.game.guess.map((top) => (
                                                    // getColor(row, top) ?
                                                    //     <Avatar key={top} sx={{ bgcolor: red[300] }} variant="circle" >{top}</Avatar> :
                                                    //     <Avatar key={top} sx={{ bgcolor: green[300] }} variant="circle" >{top}</Avatar>
                                                        getColor(row, top) ? <Button key={top} variant="contained" size="large" disableElevation color="error">{top}</Button>:<Button key={top} variant="contained" size="large" disableElevation  color="secondary">{top}</Button>    
                                                ))}
                                            </Stack>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>}

        </div>
    );
}
